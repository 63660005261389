import React, { useState, useEffect } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import BootstrapTable from "react-bootstrap-table-next";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';



const columns = [
    {
        dataField: "imei",
        text: "IMEI",
    },
];


function GestioneImei(props) {
    const [isLoading, setIsLoading] = useState(1)
    const [dataRouter, setDataRouter] = useState([]);
    const [searchImei, setSearchImei] = useState("");
    const [isAddable, setIsAddable] = useState(false);

    var ret;

    const searchImeiHandler = (router) => {
        setSearchImei(router.target.value);
    }

    useEffect(() => {
        var formBody = [];
        var payload = {
            imei: searchImei
        }

        function fetchData() {
            setTimeout(async () => {
                setIsLoading(1);

                formBody.push("payload=" + JSON.stringify(payload));
                await fetch(process.env.REACT_APP_URL_KITDATA_ADMIN_ROUTER_IMEI, {
                    credentials: 'include',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: formBody
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        setDataRouter(data);
                        if(data.length > 0) {
                            setIsAddable(false);
                        } else {
                            setIsAddable(true);
                        }
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });

                setIsLoading(0);
            });

        }

        setDataRouter([])

        fetchData();

    }, [searchImei]);


    const onSubmit = (e) => {
        e.preventDefault();
        var formBody = [];

        var url;

        url = process.env.REACT_APP_URL_KITDATA_ADMIN_ADD_ROUTER;

        const payload = (
            {
                imei: searchImei,
                used: '0',
            }
        );

        formBody.push("payload=" + JSON.stringify(payload));

        setTimeout(async () => {
            console.log("payload (ReqLabPage): " + JSON.stringify(payload));
            setIsLoading(1);
            await fetch(url, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    setIsLoading(0);
                    setSearchImei("");
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }

    const addNewRouter = () => {
        var ret = null;

        if (isAddable) {
            ret = (
                <Button variant="primary" type="submit" >
                    {"Nuovo"}
                </Button>
            );
        }

        return ret;
    }


    if (!dataRouter) {
        ret = null;
    } else {

        ret = (
            <React.Fragment>
                <h2 className="title-page">Inventario Router non utilizzati</h2>
                <p>Ricerca dei Router non ancora attribuite</p>
                <br /><br />

                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="searchRouter" style={{ width: "85%" }}>
                        <Form.Label>Ricerca</Form.Label>
                        <Form.Row>
                            <Col></Col>
                            <Col>
                                <Form.Text className="text-muted">IMEI</Form.Text>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                {addNewRouter()}
                            </Col>
                            <Col >
                                <Form.Control style={{ width: "100%" }}
                                    required
                                    type="text"
                                    onChange={searchImeiHandler}
                                    value={searchImei}
                                    placeholder="IMEI del Router" />
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col >
                                <ClipLoader
                                    size={55}
                                    color={"#a71e3b"}
                                    loading={isLoading}
                                />
                            </Col>
                        </Form.Row>
                    </Form.Group>
                </Form>


                <BootstrapTable
                    bootstrap4
                    keyField="imei"
                    data={dataRouter}
                    columns={columns}
                    striped
                    hover
                    condensed
                    bordered={false}
                />
            </React.Fragment>
        );
    }



    return ret;

}

export default GestioneImei