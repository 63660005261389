import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import BootstrapTable from "react-bootstrap-table-next";
import SelectRepoPkg from '../reqLab/SelectRepoPkg';

const studentColumns = [
    {
        dataField: "email",
        text: "email"
    }
];

function PubLabDettPage(props) {
    const [apiAuthResponse, setApiAuthResponse] = useState();
    const [apiAuthName, setApiAuthName] = useState();
    const [apiAuthSurname, setApiAuthSurname] = useState();
    const [labName, setLabName] = useState();
    const [repoPkgSel, setRepoPkgSel] = useState();
    const [baseImage, setBaseImage] = useState({ os: '', name: '' });
    const [inventory, setInventory] = useState([]);
    const [students, setStudents] = useState([]);



    useEffect(() => {
        const tabStudent = [];
        if (props.location.labProps) {
            setLabName(props.location.labProps.labName);
            setRepoPkgSel(props.location.labProps.repoPkgSel);
            setBaseImage(props.location.labProps.baseImage);
            if (props.location.labProps.inventory) {
                setInventory(props.location.labProps.inventory)
            }
            if (props.location.labProps.students) {
                props.location.labProps.students.map(( value ) => tabStudent.push({email: value}  ));
                setStudents(tabStudent)

            }
        }
        if (props.location.persProps) {
            setApiAuthResponse(props.location.persProps.apiAuthResponse);
            setApiAuthName(props.location.persProps.apiAuthName);
            setApiAuthSurname(props.location.persProps.apiAuthSurname);
        }
    }, [props.location.labProps, props.location.persProps]);

    var ret = (
        <React.Fragment>
            <h2 className="title-page">Laboratorio Virtuale {labName}</h2>
            <p>Pagina di dettaglio Laboratorio Virtuale </p>

            <Form>

                <Form.Group style={{ width: "100%" }}>
                    <Form.Label>Nome richiedente</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${apiAuthName}`}
                        readOnly
                    />
                    <br />
                    <Form.Label>Cognome richiedente</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${apiAuthSurname}`}
                        readOnly
                    />
                    <br />
                    <Form.Label>E-mail richiedente</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${apiAuthResponse}`}
                        readOnly
                    />
                    <br />

                    <Form.Label>Sistema Operativo</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${baseImage.os}`}
                        readOnly
                    />
                    <br />
                    <Form.Label>Immagine base</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${baseImage.name}`}
                        readOnly
                    />
                    <br />
                    <Form.Label>Numero macchine istanziate</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${inventory.length}`}
                        readOnly
                    />
                    <br />
                    <Form.Label>Numero studenti iscritti</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${students.length}`}
                        readOnly
                    />
                    <br />
                </Form.Group>



                <Form.Group controlId="formLabRequestSw" style={{ width: "85%" }}>
                    <Form.Label>Software richiesto</Form.Label>
                    <Form.Text className="text-muted">
                        Dati estratti dal repository LIBaaS.
                    </Form.Text>

                    <SelectRepoPkg
                        apiAuthResponse={props.apiAuthResponse}
                        apiAuthName={props.apiAuthName}
                        apiAuthSurname={props.apiAuthSurname}
                        apiAuthGroups={props.apiAuthGroups}
                        isNew={0}
                        installedPackages={repoPkgSel}
                        repoPkgSelected={setRepoPkgSel}
                    />
                </Form.Group>


                <Form.Group controlId="formLabRequestSw" style={{ width: "85%" }}>
                    <Form.Label>Studenti iscritti</Form.Label>
                    <BootstrapTable
                        keyField="email"
                        data={students}
                        columns={studentColumns}
                        striped
                        hover
                        condensed
                        bordered={false}
                    />
                </Form.Group>

            </Form>

            <Link to={{
                pathname: 'PubLab',
            }}> Pubblicazione Laboratori</Link>
        </React.Fragment>
    );

    if (props.location.labProps) {
        return ret;
    } else {
        return (
            <Link to={{
                pathname: 'PubLab',
            }}> Pubblicazione Laboratori</Link>
        );
    }

}

export default PubLabDettPage;
