import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async'

function SelectRepoPkg(props) {
  const [defOpt, setDefOpt] = useState();
  const [selOpt, setSelOpt] = useState();

  async function fetchDefaultData() {
    const tempArray = [];

    await fetch(process.env.REACT_APP_URL_REPO +
      '?feedName=' + process.env.REACT_APP_BUNDLE1_FEED +
      '&packageNameQuery=', {
      credentials: 'include',
      method: "GET"
    })
      .then((resp) => {
        return resp.json()
      })
      .then((data) => {
        data.forEach((element) => {
          tempArray.push({
            label: `[${element.name}] ${element.version}` +
              ` - ${element.packageDescription}`,
            value: `${element.name}`
          });
        });
        setDefOpt(tempArray);
        setSelOpt(tempArray);
      })
      .catch((error) => {
        console.log(error, "Errore!")
      });
  }

  useEffect(() => {
    if(props.isNew === 1)
      fetchDefaultData();
    else
      setDefOpt(props.installedPackages)
      setSelOpt(props.installedPackages)
  }, [props.isNew, props.installedPackages]);

  useEffect(() => {
    if (selOpt) {
      props.repoPkgSelected(selOpt);
    }
  })

  function filterData(inputValue, myData) {
    return myData.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  function fetchData(inputValue, callback) {
    setTimeout(() => {
      fetch(process.env.REACT_APP_URL_REPO +
        '?feedName=' + process.env.REACT_APP_DEFAULT_FEED +
        '&packageNameQuery=' + inputValue, {
        credentials: 'include',
        method: "GET"
      })
        .then((resp) => {
          return resp.json()
        })
        .then((data) => {
          const tempArray = [];
          data.forEach((element) => {
            tempArray.push({
              label: `[${element.name}] ${element.version}` +
                ` - ${element.packageDescription}`,
              value: `${element.name}`
            });
          });
          callback(filterData(inputValue, tempArray));
        })
        .catch((error) => {
          console.log(error, "Errore!")
        });
    });
  }

  function onSearchChange(selectedOption) {
    if (selectedOption) {
      setSelOpt(selectedOption);
    }
  };

  if (!defOpt)
    return (<div></div>);
  else
    return (<div>
      <AsyncSelect
        loadOptions={fetchData}
        onChange={(e) => {
          onSearchChange(e);
        }}
        defaultValue={defOpt}
        //defaultOptions
        isMulti
        components={{ DropdownIndicator: () => null }}
        noOptionsMessage={() => "Ricerca pacchetti..."}
        //isDisabled
      />
      {(
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, height: 0 }}
          value={selOpt}
          required={true}
        />
      )}


    </div>)



}

export default SelectRepoPkg;
