const rules = {
  TIM4STUDENT: {
    static: [
      "studentArea:visible",
      "tim4student-page:visit"
    ]
  },
  TIM4STUDENTREQ: {
    static: [
      "studentArea:visible",
      "tim4student-req-page:visit"
    ]
  },
  TIMCUSTOMERCARE: {
    static: [
      "adminArea:visible",
      "timConsolle-page:visit"
    ]
  },
  VOUCHERCUSTOMERCARE: {
    static: [
      "adminArea:visible",
      "voucherConsolle-page:visit"
    ]
  },
  VOUCHERSTUDENT: {
    static: [
      "studentArea:visible",
      "voucherstudent-page:visit"
    ]
  },
  POPSOSTUDENT: {
    static: [
      "studentArea:visible",
      "popsostudent-page:visit"
    ]
  },
  TRENITALIASTUDENT: {
    static: [
      "studentArea:visible",
      "trenitaliastudent-page:visit"
    ]
  },
  ADMIN: {
    static: [
      "studentArea:visible",
      "tim4student-page:visit",
      "tim4student-req-page:visit",
      "adminArea:visible",
      "timConsolle-page:visit",
      "voucherConsolle-page:visit",
      "voucherstudent-page:visit",
      "popsostudent-page:visit",
      "trenitaliastudent-page:visit"
    ]
  }
};

export default rules;
