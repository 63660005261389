import React from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

function ApplySimConfirm(props) {
    var ret;

    if (!props.recaptchaToken) {
        switch (props.statusSim) {
            case "confirmed":
                ret = (
                    <React.Fragment>
                        <Alert variant="success">
                            <Alert.Heading>Dati confermati con successo!</Alert.Heading>
                            <p>
                                La richiesta di attivazione SIM è stata presa in carico.
                                Nei prossimi giorni riceverà una e-mail di conferma all'avvenuta attivazione della SIM.
                            </p>
                        </Alert>
                        <Button href="/">Torna alla Home Page</Button>{' '}

                    </React.Fragment>
                );
                break;                
            default: 
                ret = null;
        }
    } else {
        switch (props.statusSim) {
            case "created":
            case "visited":
                ret = (
                    <React.Fragment>
                        <br />
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>

                    </React.Fragment>
                );
                break;            
            case "spent":
                ret = (
                    <React.Fragment>
                        <br />
                        <Button variant="primary" type="submit">
                            Conferma definitivamente i dati
                        </Button>

                    </React.Fragment>
                );
                break;
            default:
                ret = (
                    <React.Fragment>
                        <Alert variant="danger">

                            <Alert.Heading>Si è verificato un errore!</Alert.Heading>
                            <p>
                                Contattare l'assistenza.
                                </p>
                        </Alert>
                        <Button href="/">Torna alla Home Page</Button>
                    </React.Fragment>
                );
        }


    }

    return ret;
}

export default ApplySimConfirm;