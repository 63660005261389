import React, { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import Form from 'react-bootstrap/Form';

function AgreementVoucher(props) {
    const [agreementChecked, setAgreementChecked] = useState(false);

    var agreement;

    const toggleAgreement = () => {
        if (agreementChecked) {
            setAgreementChecked(false);
        } else {
            setAgreementChecked(true);
        }
    };

    if (props.numPdvWeek === 0) {
        agreement = (
            <Form.Label className="text-left" style={{ width: "100%" }}>Non è più possibile accettare richieste di Voucher </Form.Label>
        );
    } else {
        agreement = (
            <Form.Group id="formGridCheckbox">
                <hr />
                <Form.Label className="text-center" style={{ width: "100%" }}>INFORMATIVA PER IL TRATTAMENTO DEI DATI INERENTI AL PROCESSO DI RICHIESTA DI VOUCHER PER L'ACQUISTO DI PC, NOTEBOOK O TABLET – INIZIATIVA "VOUCHER4STUDENT"</Form.Label>
                <TextareaAutosize
                    readOnly
                    cols={50}
                    maxRows={10}
                    wrap="true"
                    value="INFORMATIVA PER IL TRATTAMENTO DEI DATI INERENTI AL PROCESSO DI RILASCIO VOUCHER
                    Gentile utente, ai sensi dell’art. 13 del Regolamento UE 2016/679 – cd. GDPR, la informiamo su come
                    tratteremo i suoi dati personali.
                    Il trattamento dei suoi dati personali sarà improntato ai principi di correttezza, liceità e trasparenza e
                    di tutela della sua riservatezza e dei suoi diritti e potrà essere svolto in via manuale o in via elettronica
                    o comunque con l’ausilio di strumenti informatizzati o automatizzati. Potrà consistere in qualsiasi
                    operazione compiuta con o senza l&#39;ausilio di processi automatizzati come la raccolta, la registrazione,
                    l&#39;organizzazione, la strutturazione, la conservazione, l&#39;elaborazione, la selezione, il blocco,
                    l’adattamento o la modifica, l’estrazione, la consultazione, l’uso, la comunicazione mediante
                    trasmissione, la diffusione o qualsiasi altra forma di messa a disposizione, il raffronto o
                    l&#39;interconnessione, la limitazione, la cancellazione o la distruzione.
                    Chi è il Titolare del trattamento dei suoi dati?
                    Il Titolare del trattamento, cioè l’organismo che determina come e perché i suoi dati sono trattati, è
                    l’Università degli Studi di Milano-Bicocca, con sede in Piazza dell’Ateneo Nuovo 1, 20126 Milano, nella
                    persona della Rettrice prof.ssa Giovanna Iannantuoni, suo Legale Rappresentante, (d’ora in avanti:
                    Titolare). Può rivolgersi al Titolare scrivendo all’indirizzo fisico sopra riportato o inviando una e-mail a
                    rettorato@unimib.it oppure una PEC a ateneo.bicocca@pec.unimib.it
                    Chi è il Responsabile della protezione dei suoi dati?
                    Presso l’Ateneo Bicocca è presente il Responsabile della Protezione Dati (d’ora in avanti, RPD) a cui
                    potersi rivolgere per tutte le questioni relative al trattamento dei propri dati personali e per l’esercizio
                    dei diritti derivanti dal GDPR. Il RPD è la dott.ssa Maria Bramanti, che può essere contattata
                    all’indirizzo di posta elettronica rpd@unimib.it o alla PEC rpd@pec.unimib.it
                    A quale scopo trattiamo i suoi dati?
                    I suoi dati personali sono trattati, sulla base dell’art. 6, co. 1 del GDPR, per svolgere tutte le funzioni
                    affidate alle competenti strutture dell’Ateneo; nello specifico, la nostra struttura tratterà i suoi dati
                    per:
                     Evasione della richiesta da parte degli studenti, per il rilascio del voucher per l’acquisto di pc,
                    notebook o tablet forniti da: C&amp;C Consulting S.p.A., Med Computer S.r.l., MediaWorld
                    (MediaMarket S.p.A.);
                     fruizione di tutti e/o in parte dei servizi erogati in funzione della richiesta;
                     finalità di miglioramento della didattica a distanza.
                    
                    Informativa sul trattamento dei dati personali ai sensi dell’art. 13 del Regolamento UE 2016/679 in
                    materia di protezione dei dati personali
                    
                    A chi possiamo comunicare i suoi dati?
                    I suoi dati sono trattati dai soggetti afferenti alle strutture dell’Ateneo autorizzati dal Titolare al
                    trattamento, in relazione alle loro funzioni e competenze.
                    Inoltre, il Titolare potrà comunicare i suoi dati personali all’esterno ai seguenti soggetti terzi, perché la
                    loro attività è necessaria al conseguimento delle finalità sopra indicate, anche rispetto alle funzioni
                    loro attribuite dalla legge:
                     C&amp;C Consulting S.p.A. – fornitore di pc, notebook o tablet e oggetto dell’iniziativa;
                     Med Computer S.r.l. – fornitore di pc, notebook o tablet e oggetto dell’iniziativa;
                     MediaWorld (MediaMarket S.p.A.) – fornitore di pc, notebook o tablet e oggetto dell’iniziativa;
                     Autorità giudiziaria;
                     Altri soggetti pubblici o privati ai quali l’Ateneo affida dei servizi di propria competenza, nei limiti
                    previsti dalla legge, in outsourcing o con i quali sono poste in essere delle convenzioni o degli
                    accordi.
                    Non è previsto il trasferimento dei dati in territori extra-UE o ad organizzazioni internazionali. Nel caso
                    se ne verificasse l’esigenza, in primo luogo le sarà fornita un’informativa specifica e, nel caso in cui per il
                    Paese di destinazione non sia stata emanata una decisione di adeguatezza, oppure non siano disponibili
                    adeguate garanzie di protezione, le verrà richiesto il consenso per procedere con il trasferimento.
                    È obbligato a comunicarci i suoi dati?
                    Qualora volesse procedere sì, perché in caso di rifiuto, l’Università non potrà procedere nelle specifiche
                    attività e assolvere le richieste avanzate. È altresì necessario esprimere formalmente il proprio
                    consenso al trattamento dei dati.
                    Per quanto tempo verranno conservati i suoi dati?
                    I dati raccolti per la richiesta di utilizzo del voucher saranno conservati per 12 mesi.
                    Se i suoi dati personali sono contenuti nelle banche dati del Titolare, sono conservati illimitatamente.
                    Se i suoi dati personali sono contenuti in documenti analogici e/o digitali prodotti o posseduti dal
                    Titolare, sono soggetti ai tempi di conservazione previsti dalla normativa; le diverse tempistiche sono
                    indicate nelle “Linee guida per lo scarto dei documenti analogici e digitali” consultabile dal sito internet
                    di Ateneo.
                    I log di autenticazione, ove presenti, verranno cancellati dopo 180 giorni.
                    Quali sono i suoi diritti e come può esercitarli?
                    Ha il diritto di:
                    
                    Informativa sul trattamento dei dati personali ai sensi dell’art. 13 del Regolamento UE 2016/679 in
                    materia di protezione dei dati personali
                     accedere ai suoi dati personali;
                     ottenere la rettifica o la cancellazione dei dati o la limitazione del relativo trattamento;
                     se i dati sono in formato elettronico, richiederne la portabilità;
                     opporsi al trattamento;
                     proporre reclamo all&#39;autorità di controllo.
                    Può far valere i suoi diritti rivolgendosi al Titolare e/o al RPD; il Titolare è tenuto a risponderle entro 30
                    giorni dalla data di ricezione della richiesta, (termine che può essere esteso fino a 90 giorni in caso di
                    particolare complessità dell’istanza).
                    Nel caso ritenga che il trattamento dei dati che la riguardano non sia conforme alle disposizioni vigenti
                    ovvero se la risposta ad un&#39;istanza con cui ha esercitato uno o più dei diritti previsti dagli articoli 15-22
                    del GDPR non pervenga nei tempi indicati o non sia soddisfacente, può rivolgersi all&#39;autorità giudiziaria
                    o al Garante per la protezione dei dati personali.
                    Sarà sottoposto a processi decisionali automatizzati?
                    No, non sarà sottoposto ad alcuna decisione basata unicamente su trattamenti automatizzati (compresa la
                    profilazione), a meno che lei non abbia per questo prestato esplicitamente il suo consenso.
                    I suoi dati sono al sicuro?
                    I suoi dati sono trattati in maniera lecita e corretta, adottando le opportune misure di sicurezza volte ad
                    impedire accessi non autorizzati, divulgazione, modifica o distruzione non autorizzata degli stessi.
                    [La presente informativa è aggiornata al 15/09/2020]
                    "
                />

                <Form.Check
                    required
                    onChange={toggleAgreement}
                    type="checkbox"
                    id="agreement"
                    label={"Dichiaro di aver preso visione dell' INFORMATIVA PER IL TRATTAMENTO DEI DATI INERENTI AL PROCESSO DI RICHIESTA DI VOUCHER PER L'ACQUISTO DI PC, NOTEBOOK O TABLET, ai fini della partecipazione dell’ Iniziativa \"VOUCHER4STUDENT\""}
                />
                <br />
                <hr />

                <Form.Label className="text-center" style={{ width: "100%" }}>CONSENSO</Form.Label>
                <Form.Check
                    required
                    onChange={toggleAgreement}
                    type="checkbox"
                    id="agreement2"
                    label={"Acconsento al trattamento dei miei dati personali ai sensi dell’ Art. 6 paragrafo 1 - lettera a) del RE 679/2016 e secondo le modalità e nei limiti di cui all’informativa relativa all' Iniziativa \"VOUCHER4STUDENT\""}
                />
                <br />
                <br />


                <Form.Label className="left-center" style={{ width: "100%" }}>
                    A seguito della presa visione dell' INFORMATIVA PER IL TRATTAMENTO DEI DATI INERENTI
                    AL PROCESSO DI RICHIESTA DI VOUCHER PER L'ACQUISTO DI PC, NOTEBOOK O TABLET – INIZIATIVA
                    “VOUCHER4STUDENT”, dell'espressione di Consenso e della dichiarazione di manleva, aderisco
                    all'iniziativa VOUCHER4STUDENT nelle modalità e nei limiti descritti  cliccando sul bottone 'Submit'
                </Form.Label>


            </Form.Group>
        );
    }
    return agreement;

}


export default AgreementVoucher;
