import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactCodeInput from 'react-code-input';
import ReqPinConfirm from './ReqPinConfirm';



function PopsostudentPage(props) {
    const [agreementChecked, setAgreementChecked] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [isLoading, setIsLoading] = useState(0);
    const [inputPin, setInputPin] = useState();
    const [statusRequest, setStatusRequest] = useState();

    var myIndi;
    var myRet;

    useEffect(() => {
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        var formBody = [];

        const payload = {
            inputPin: inputPin
        }

        setTimeout(async () => {
            setIsLoading(1);
            formBody.push("payload=" + JSON.stringify(payload));
            await fetch(process.env.REACT_APP_URL_CHK_PIN, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    setIsSubmitted(1);
                    setIsLoading(0);
                    setStatusRequest(data.statusRequest);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }


    const toggleAgreement = () => {
        if (agreementChecked) {
            setAgreementChecked(false);
        } else {
            setAgreementChecked(true);
        }
    };

    const pinProps = {
        //className: reactCodeInput,
        inputStyle: {
            fontFamily: 'monospace',
            margin: '4px',
            MozAppearance: 'textfield',
            width: '35px',
            borderRadius: '3px',
            fontSize: '24px',
            height: '37px',
            paddingLeft: '7px',
            //backgroundColor: 'black',
            //color: 'lightskyblue',
            color: '#a71e3c',
            border: '1px solid #a71e3c'
        },
        inputStyleInvalid: {
            fontFamily: 'monospace',
            margin: '4px',
            MozAppearance: 'textfield',
            width: '35px',
            borderRadius: '3px',
            fontSize: '24px',
            height: '37px',
            paddingLeft: '7px',
            backgroundColor: 'black',
            color: 'red',
            border: '1px solid red'
        }
    };


    const onChangePin = (pinValue) => {
        setInputPin(pinValue);
    }

    if (props.stuIndiDom && props.stuIndiRes) {
        myIndi = (props.stuIndiDom.viaDom ?
            props.stuIndiDom.viaDom + ", " + props.stuIndiDom.numCivDom + " " +
            props.stuIndiDom.capDom + " " + props.stuIndiDom.comuneDom + " " +
            props.stuIndiDom.provDom + " " +
            props.stuIndiDom.frazDom + " " + props.stuIndiDom.citstraDom + " " +
            props.stuIndiDom.nazioneDom
            :
            props.stuIndiRes.viaRes + ", " + props.stuIndiRes.numCivRes + " " +
            props.stuIndiRes.capRes + " " + props.stuIndiRes.comuneRes + " " +
            props.stuIndiDom.provRes + " " +
            props.stuIndiRes.frazRes + " " + props.stuIndiRes.citstraRes + " " +
            props.stuIndiRes.nazioneRes
        );

    }


    if (!props.apiAuthName)
        myRet = null;
    else {
        if (!props.stuMatricola) {
            myRet = (
                <React.Fragment>
                    <h2 className="title-page">Conferma ricezione Badge Ateneo+</h2>
                    <p>Posizione irregolare. Contattare l'assistenza.</p>
                </React.Fragment>
            )
        } else {
            myRet = (
                <React.Fragment>
                    <h2 className="title-page">Conferma ricezione Badge Ateneo+</h2>
                    <p>Da questa pagina è possibile confermare la ricezione del Badge Ateneo+</p>
                    <Form onSubmit={onSubmit}>
                        <Form.Group style={{ width: "100%" }}>
                            <Form.Label>Nome titolare</Form.Label>
                            <Form.Control style={{ width: "100%" }}
                                type="text"
                                placeholder={`${props.apiAuthName}`}
                                readOnly
                            />
                            <br />
                            <Form.Label>Cognome titolare</Form.Label>
                            <Form.Control style={{ width: "100%" }}
                                type="text"
                                placeholder={`${props.apiAuthSurname}`}
                                readOnly
                            />
                            <br />
                            <Form.Label>E-mail titolare</Form.Label>
                            <Form.Control style={{ width: "100%" }}
                                type="text"
                                placeholder={`${props.apiAuthResponse}`}
                                readOnly
                            />
                            <br />
                            <Form.Label>Matricola titolare</Form.Label>
                            <Form.Control style={{ width: "100%" }}
                                type="text"
                                placeholder={`${props.stuMatricola}`}
                                readOnly
                            />
                            <br />
                            <Form.Label>Indirizzo</Form.Label>
                            <Form.Text className="text-muted">
                                Verificare la validità dell'indirizzo riportato.
                                In caso di inesattezze è possibile aggiornare il proprio recapito da <a href="https://s3w.si.unimib.it">Segreterie on Line</a>.
                            </Form.Text>
                            <Form.Control style={{ width: "100%" }}
                                type="text"
                                placeholder={`${myIndi}`}
                                readOnly
                            />
                            <br />
                        </Form.Group>

                        <Form.Group>
                            <Form.Text className="text-muted">
                                Inserire il PIN ricevuto
                            </Form.Text>
                            <ReactCodeInput
                                type='text'
                                fields={10}
                                onChange={onChangePin}
                                {...pinProps}
                            />
                            <input
                                tabIndex={-1}
                                autoComplete="new-password"
                                style={{ opacity: 0, height: 0 }}
                                required={true}
                                value={inputPin}
                            />
                        </Form.Group>

                        <Form.Group id="formGridCheckbox">
                            <Form.Text className="text-muted">
                                Con il seguente checkbox si conferma di aver letto e accettare il regolamento.
                            </Form.Text>
                            <Form.Check
                                required
                                onChange={toggleAgreement}
                                type="checkbox"
                                id="agreement"
                                label="Acconsento al trattamento dei miei dati personali"
                            />
                            <br />
                        </Form.Group>

                        <div>
                            {
                                <ReqPinConfirm
                                    isSubmitted={isSubmitted}
                                    statusRequest={statusRequest}
                                    stuMatricola={props.stuMatricola}
                                />
                            }
                            <br />
                            <ClipLoader
                                size={35}
                                color={"#a71e3b"}
                                loading={isLoading}
                            />
                        </div>
                    </Form>

                </React.Fragment>
            );
        }
    }

    return myRet;
}

export default PopsostudentPage;
