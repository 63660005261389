import React from 'react';
import Dropzone from 'react-dropzone-uploader';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import 'react-dropzone-uploader/dist/styles.css'

function L3UploadAttivate(props) {
    const getUploadParams = () => {
        return {
            fields: {
                chiave: process.env.REACT_APP_URL_CONSOLLETIM_L3_AUTH
            },
            url: process.env.REACT_APP_URL_CONSOLLETIM_L3
        }
    }

    var ret;

    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) }

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files, allFiles) => {
        console.log(files.map(f => f.meta))
        allFiles.forEach(f => f.remove())
    }

    ret = (
        <React.Fragment>
            <h2 className="title-page">Estrazione L3</h2>
            <p>Upload SIM attivate</p>
            <br /><br />

            <Form>
                <Form.Group controlId="uploader" style={{ width: "85%" }}>
                    <Form.Label>Upload file L3</Form.Label>
                    <Form.Row>
                        <Col>
                            <Dropzone
                                getUploadParams={getUploadParams}
                                onChangeStatus={handleChangeStatus}
                                onSubmit={handleSubmit}
                                maxFiles={1}
                                multiple={false}
                                inputContent="Drop TIM L3 File or Click to Browse"
                                accept=".xlsx,.xls"
                                styles={{
                                    dropzone: {
                                        minHeight: 200, maxHeight: 250,
                                        minWidth: 300, maxWidth: 600
                                    }
                                }}
                            />
                        </Col>
                    </Form.Row>
                </Form.Group>
            </Form>


        </React.Fragment>
    );

    return ret;

}

export default L3UploadAttivate;