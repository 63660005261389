import React from 'react';
import Form from 'react-bootstrap/Form';


function SelectBaseImage(props) {

    var ret;

    ret = (
        <Form.Group controlId="formLabRequestSo" style={{ width: "85%" }}>
            <Form.Label>Scelta immagine base</Form.Label>
            <Form.Check
                type="radio"
                id={process.env.REACT_APP_DEFAULT_BASE_IMAGE}
                label={`Windows 10`}
                onChange={props.labBaseImageHandler}
                name="os"
                checked={props.baseImage.name === process.env.REACT_APP_DEFAULT_BASE_IMAGE}
            />

            <Form.Check
                type="radio"
                id={`linux_ubuntu`}
                label={`Linux Ubuntu`}
                onChange={props.labBaseImageHandler}
                name="os"
                disabled
            />
            <Form.Check
                type="radio"
                id={`linux_root_fisica`}
                label={`Linux (ROOT) - Fisica`}
                onChange={props.labBaseImageHandler}
                name="os"
                disabled
            />
            <br />
        </Form.Group>

    );

    return ret;
}

export default SelectBaseImage;