import React, { useEffect, useState } from 'react';
import Can from "../Can";
import ApplySimPage from './ApplySimPage';
import ReqSimConfirm from './ReqSimConfirm'

function ApplySimHome(props) {
    const [apiSim, setApiSim] = useState();

    var myHome;


    useEffect(() => {
        const fetchData = () => {
            setTimeout(async () => {
                await fetch(process.env.REACT_APP_URL_SIM, {
                    credentials: 'include',
                    method: "GET"
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        if (data) {
                            setApiSim({
                                idSim: data.idSim,
                                qrCode: data.qrCode,
                                stato: data.stato,
                                isDomicilio: data.isDomicilio,
                                pdv: data.pdv
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });

            });
        };
        fetchData();

    }, []);

    if (!props.apiAuthGroups) {
        myHome = (
            <React.Fragment>
                <h2>Non autorizzato</h2>
            </React.Fragment>
        )
    } else {
        myHome = (
            <Can
                role={props.apiAuthGroups}
                perform="tim4student-page:visit"
                yes={() => {
                    var ret;
                    if (apiSim) {
                        if (
                            (apiSim.isDomicilio === 0 && (apiSim.stato === "confirmed" || apiSim.stato === "active" ) ) ||
                            (apiSim.isDomicilio === 1)

                        ) {
                            ret = (
                                <ApplySimPage
                                    checkedIdSim={String(apiSim.idSim)}
                                    checkedQrCode={apiSim.qrCode}
                                />
                            );
                        } else {
                            ret = (
                                <React.Fragment>
                                    <h3>Risulta una SIM dati in attesa di attivazione associata al suo account</h3>
                                    <br />
                                    <p> Recarsi presso il Centro TIM </p>
                                    <br />
                                    <ReqSimConfirm
                                        qrCode={apiSim.qrCode}
                                        idSim={apiSim.idSim}
                                        isDomicilio={apiSim.isDomicilio}
                                        isSubmitted={1}
                                        statusRequest={"reprint"}
                                        pdv={apiSim.pdv}
                                        stuMatricola={props.stuMatricola}
                                        apiAuthName={props.apiAuthName}
                                        apiAuthSurname={props.apiAuthSurname}
                                    />
                                </React.Fragment>
                            )
                        }
                    } else {
                        ret = (
                            <React.Fragment>
                                <h3>Non risultano SIM dati attive associate al suo account</h3>
                                <br />
                                <br />
                            </React.Fragment>
                        );

                    }
                    return ret;
                }
                }
                no={() => null}
            />
        )
    }


    return myHome;
}

export default ApplySimHome;