import React, { useState, useEffect } from 'react';
import ApplySimPage from "./ApplySimPage";


function ApplySim(props) {
    const [checkedIdSim, setCheckedIdSim] = useState();
    const [checkedQrCode, setCheckedQrCode] = useState();


    useEffect(() => {
        // sanity check parametro idSim
        if (props.match.params.idSim.match(/^([0-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|[1-8][0-9]{4}|9[0-8][0-9]{3}|99[0-8][0-9]{2}|999[0-8][0-9]|9999[0-9]|[1-8][0-9]{5}|9[0-8][0-9]{4}|99[0-8][0-9]{3}|999[0-8][0-9]{2}|9999[0-8][0-9]|99999[0-9]|[1-8][0-9]{6}|9[0-8][0-9]{5}|99[0-8][0-9]{4}|999[0-8][0-9]{3}|9999[0-8][0-9]{2}|99999[0-8][0-9]|999999[0-9]|[1-8][0-9]{7}|9[0-8][0-9]{6}|99[0-8][0-9]{5}|999[0-8][0-9]{4}|9999[0-8][0-9]{3}|99999[0-8][0-9]{2}|999999[0-8][0-9]|9999999[0-9])$/)) {
            setCheckedIdSim(props.match.params.idSim);
        }

        // sanity check parametro qrCode
        if (props.match.params.qrCode.match(/^[A-Za-z0-9]{128}$/)) {
            setCheckedQrCode(props.match.params.qrCode);
        }

    }, [props.match.params.idSim, props.match.params.qrCode]);

    var myHome;

    // autenticazione basata su sim
    if (checkedIdSim && checkedQrCode) {
        myHome = (
            <React.Fragment>
                <ApplySimPage 
                    checkedIdSim={checkedIdSim}
                    checkedQrCode={checkedQrCode}
                />
            </React.Fragment>
        );
    } else {
        myHome = (
            <React.Fragment>
                <p>Invalid</p>
            </React.Fragment>
        );
    }



    return myHome;
}

export default ApplySim;