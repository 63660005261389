import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import ClipLoader from "react-spinners/ClipLoader";
import ReqVoucherConfirm from './ReqVoucherConfirm';
import AgreementVoucher from './AgreementVoucher';

function VoucherstudentPage(props) {
    const [isLoading, setIsLoading] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [statusRequest, setStatusRequest] = useState();   
    const [idVoucher, setIdVoucher] = useState();
    const [qrCode, setQrCode] = useState();

    var myRet;

    const onSubmit = (e) => {
        e.preventDefault();
        var formBody = [];

        setTimeout(async () => {
            setIsLoading(1);
            await fetch(process.env.REACT_APP_URL_REQ_VOUCHER, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    setIsSubmitted(1);
                    setIsLoading(0);
                    setIdVoucher(data.idVoucher);
                    setQrCode(data.qrCode);                    
                    setStatusRequest(data.statusRequest);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }


    useEffect(() => {

    }, []);


    if (!props.apiAuthName)
        myRet = null;
    else {
        if (!props.stuMatricola) {
            myRet = (
                <React.Fragment>
                    <h2 className="title-page">Richiesta Voucher per PC, notebook o Tablet</h2>
                    <p>Posizione irregolare. Contattare l'assistenza.</p>
                </React.Fragment>
            )
        } else {
            myRet = (
                <React.Fragment>
                    <h2 className="title-page">Richiesta Voucher per PC, notebook o Tablet</h2>
                    <p>Da questa pagina è possibile richiedere il Voucher utile per l'acquisto di PC, notebook o Tablet</p>
                    <Form onSubmit={onSubmit}>
                        <Form.Group style={{ width: "100%" }}>
                            <Form.Label>Nome richiedente</Form.Label>
                            <Form.Control style={{ width: "100%" }}
                                type="text"
                                placeholder={`${props.apiAuthName}`}
                                readOnly
                            />
                            <br />
                            <Form.Label>Cognome richiedente</Form.Label>
                            <Form.Control style={{ width: "100%" }}
                                type="text"
                                placeholder={`${props.apiAuthSurname}`}
                                readOnly
                            />
                            <br />
                            <Form.Label>Codice Fiscale richiedente</Form.Label>
                            <Form.Control style={{ width: "100%" }}
                                type="text"
                                placeholder={`${props.stuCodFis}`}
                                readOnly
                            />
                            <br />
                            <Form.Label>E-mail richiedente</Form.Label>
                            <Form.Control style={{ width: "100%" }}
                                type="text"
                                placeholder={`${props.apiAuthResponse}`}
                                readOnly
                            />
                            <br />
                            <Form.Label>Matricola richiedente</Form.Label>
                            <Form.Control style={{ width: "100%" }}
                                type="text"
                                placeholder={`${props.stuMatricola}`}
                                readOnly
                            />
                            <br />
                        </Form.Group>

                        <AgreementVoucher
                            numPdvWeek={1}
                        />

                        <div>
                            <ReqVoucherConfirm
                                isSubmitted={isSubmitted}
                                statusRequest={statusRequest}
                                stuMatricola={props.stuMatricola}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                qrCode={qrCode}
                                idVoucher={idVoucher}
                            />
                            <br />
                            <ClipLoader
                                size={35}
                                color={"#a71e3b"}
                                loading={isLoading}
                            />
                        </div>
                    </Form>

                </React.Fragment>
            );
        }

    }

    return myRet;

}

export default VoucherstudentPage;
