import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AsyncSelect from 'react-select/async'


function SimPdv(props) {
    const [numPdv, setNumPdv] = useState();

    var myRet;
    var selectWeek;
    var selectWeekHeader;


    const fetchData = (inputValue, callback) => {
        const tempArray = [];
        setTimeout(async () => {
            await fetch(process.env.REACT_APP_URL_PDV_SIM + '?comune=' + props.comune +
                '&prov=' + props.prov, {
                credentials: 'include',
                method: "GET",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    data.forEach((element) => {
                        var myZona = element.zonaComune !== 'undefined' ? element.zonaComune + ' -' : "";
                        tempArray.push({
                            label: `${myZona} ${element.ragioneSociale} ` +
                                `${element.indirizzoPdv} ${element.capPdv} ` +
                                `${element.comunePdv}`,
                            value: {
                                codPdv: `${element.codPdv}`,
                                desPdv: `${myZona} ${element.ragioneSociale} ` +
                                    `${element.indirizzoPdv} ${element.capPdv} ${element.comunePdv}`
                            }
                        });
                    });
                    callback(filterData(inputValue, tempArray));
                })
                .then(() => {
                    setNumPdv(tempArray.length);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }



    const fetchDataWeek = (inputValue, callback) => {
        const tempArray = [];

        if (props.selOpt)
            setTimeout(async () => {
                await fetch(process.env.REACT_APP_URL_PDV_WEEK_SIM + '?comune=' + props.comune +
                    '&prov=' + props.prov + '&codPdv=' + props.selOpt.selectedOption.value.codPdv, {
                    credentials: 'include',
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        data.forEach((element) => {
                            tempArray.push({
                                label: `${element.week}`,
                                value: {
                                    idWeek: `${element.idWeek}`,
                                    week: `${element.week}`
                                }
                            });
                        });
                        callback(filterData(inputValue, tempArray));
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });
            });

    }


    function onSearchChange(selectedOption) {
        if (selectedOption) {
            props.setSelOpt({
                selectedOption
            });
        }
    };

    function onSearchChangeWeek(selectedOptionWeek) {
        if (selectedOptionWeek) {
            props.setSelOptWeek({
                selectedOptionWeek
            });
        }
    };

    function filterData(inputValue, myData) {
        return myData.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };


    useEffect(() => {
        const fetchData = () => {
            const tempArray = [];
            setTimeout(async () => {
                await fetch(process.env.REACT_APP_URL_PDV_SIM + '?comune=' + props.comune +
                    '&prov=' + props.prov, {
                    credentials: 'include',
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        data.forEach((element) => {
                            tempArray.push(`${element.codPdv}`);
                        });
                    })
                    .then(() => {
                        setNumPdv(tempArray.length);
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });
            });
        }
        fetchData();
    }, [props.comune, props.prov]);


    if (props.selOpt) {
        selectWeek = (
            <React.Fragment >
                <AsyncSelect
                    loadOptions={fetchDataWeek}
                    onChange={(e) => {
                        onSearchChangeWeek(e);
                    }}
                    defaultOptions={true}
                />

                <input
                    tabIndex={-1}
                    autoComplete="off"
                    style={{ opacity: 0, height: 0 }}
                    value={props.selOptWeek}
                    required={true}
                />
            </React.Fragment>
        );

        selectWeekHeader = (
            <Form.Text className="text-muted">
                Selezionare la finestra temporale
            </Form.Text>
        );
    }

    if (!props.apiAuthName)
        myRet = null;
    else {
        if (numPdv > 0 && props.stuIndiRes.forzaSpedPosta === "0") {
            props.setIsDomicilio(0);
            myRet = (
                <React.Fragment >
                    <Form.Label>Selezionare il Negozio TIM convenzionato e la finestra temporale per il ritiro</Form.Label>
                    <Row>
                        <Col>
                            <Form.Text className="text-muted">
                                Per effettuare il ritiro della SIM e Router è necessario selezionare un Negozio TIM
                            </Form.Text>
                        </Col>
                        <Col>
                            {selectWeekHeader}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                <AsyncSelect
                                    loadOptions={fetchData}
                                    onChange={(e) => {
                                        onSearchChange(e);
                                    }}
                                    defaultOptions={true}
                                />
                                {(
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{ opacity: 0, height: 0 }}
                                        value={props.selOpt}
                                        required={true}
                                    />
                                )}
                            </div>
                        </Col>
                        <Col>
                            <div>
                                {selectWeek}
                            </div>
                        </Col>
                    </Row>
                </React.Fragment >
            )
        } else {
            props.setIsDomicilio(1);
            myRet = (
                <React.Fragment >
                    <Form.Label>Consegna SIM e Router a domicilio </Form.Label>
                    <Form.Text className="text-muted">
                        Il materiale sarà recapitato al seguente indirizzo.
                    </Form.Text>

                    <Form.Label>Indirizzo</Form.Label>
                    <Form.Text className="text-muted">
                        Prima di confermare verificare accuratamente la validità dell'indirizzo riportato. Una volta completata la presente richiesta di SIM e Router non sarà più possibile modificare l'indirizzo di spedizione.
                        In caso di inesattezze è possibile aggiornare il proprio recapito da <a href="https://s3w.si.unimib.it">Segreterie on Line</a> e attendere che l'indirizzo aggiornato venga visualizzato in questa pagina.
                        Non sarà possibile modificare l'indirizzo di consegna una volta confermata la presente richiesta.
                    </Form.Text>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.myIndi}`}
                        readOnly
                    />
                    <br />
                </React.Fragment >

            )
        }

    }

    return myRet;
}

export default SimPdv;
