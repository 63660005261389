import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';


// Create styles
const styles = StyleSheet.create({
    page: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    view: {
        width: '100%',
        height: '100%',
        padding: 0,
        backgroundColor: 'white',
    },
    qrCode: {
        width: '300px',
        height: 'auto',
        marginVertical: 25,
        marginHorizontal: 110,
    },
    barCode: {
        width: '100px',
        height: 'auto',
        marginVertical: 15,
        marginHorizontal: 100,
    },
    bicLogo: {
        width: '90px',
        height: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 210,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        textAlign: 'center',
    },
    section2: {
        margin: 5,
        padding: 0,
        flexGrow: 1,
        fontSize: 12,
        textAlign: 'left',
        marginBottom: 30,
    },
    section4: {
        margin: 0,
        padding: 0,
        flexGrow: 1,
        fontSize: 12,
        textAlign: 'left',
        marginHorizontal: 5,        
    }
});


// Create Document Component
const VoucherDoc = (props) => {
    var bicLogo = process.env.REACT_APP_URL_LOGO_UNIMIB;
    var myRet = (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={{ textAlign: 'center' }}>
                    <Image style={styles.bicLogo} src={bicLogo} alt="bicLogo" />
                    <Text style={styles.section}>Università degli Studi di Milano-Bicocca</Text>
                </View>
                <View>
                    <Text style={styles.section2}>
                        Importo del Voucher € 100,00 (cento/00) valido per l'acquisto di un PC, notebook o tablet dal 21 settembre 2020 al 10 dicembre 2020. {"\n"}
                        Link al regolamento per l'utilizzo: http://www.unimib.it
                    </Text>
                    <Text style={styles.section4}>Nome: {props.apiAuthName}</Text>
                    <Text style={styles.section4}>Cognome: {props.apiAuthSurname}</Text>
                    <Text style={styles.section4}>Matricola: {props.stuMatricola}</Text>                    
                    <Image style={styles.qrCode} src={props.qrCodeDataUri} alt="qrCode" />
                    <Image style={styles.barCode} src={props.barCodeDataUri} alt="barCode" />
                </View>
            </Page>
        </Document>

    );

    return myRet;

};

export default VoucherDoc;