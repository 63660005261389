import React from 'react';
import Form from 'react-bootstrap/Form';
import AsyncSelect from 'react-select/async'

function SelectRouter(props) {
    var ret;

    const fetchImei = (inputValue, callback) => {
        const tempArray = [];
        var formBody = [];
        const payload = {
            idSim: props.idSim,
            qrCode: props.qrCode,
            imei: inputValue
        }
        setTimeout(async () => {
            formBody.push("payload=" + JSON.stringify(payload));
            await fetch(process.env.REACT_APP_URL_KITDATA_ROUTER_IMEI, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    data.forEach((element) => {
                        tempArray.push({
                            label: `${element.imei}`,
                            value: `${element.imei}`
                        });
                    });
                    callback(filterData(inputValue, tempArray));
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }

    function filterData(inputValue, myData) {
        return myData.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    function onSearchChangeImei(selectedOption) {
        if (selectedOption) {
            props.setImei(selectedOption.value);
        }
    };

    var myPlaceHolder;
    if (props.imei) {
        myPlaceHolder = props.imei
    } else {
        myPlaceHolder = "Codice IMEI del Router"
    }

    ret = (
        <React.Fragment>
            <Form.Label>Codice IMEI del Router</Form.Label>
            <div>
                <AsyncSelect
                    placeholder={myPlaceHolder}
                    loadOptions={fetchImei}
                    onChange={(e) => {
                        onSearchChangeImei(e);
                    }}
                    //defaultOptions={true}
                    components={{ DropdownIndicator: () => null }}
                    noOptionsMessage={() => "Ricerca IMEI..."}
                />
                <input
                    tabIndex={-1}
                    autoComplete="off"
                    style={{ opacity: 0, height: 0 }}
                    required={true}
                    value={props.imei}
                />

            </div>

        </React.Fragment>
    )


    return ret;

}


export default SelectRouter;