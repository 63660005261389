
import React, { useState, useEffect } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import ClipLoader from "react-spinners/ClipLoader";
import SwitchPubLab from './SwitchPubLab';
import { Link } from "react-router-dom";



const labColumns = [
    {
        dataField: "email",
        text: "email",
        hidden: "true"
    },
    {
        dataField: "labName",
        text: "Nome Laboratorio",
        sort: true
    },
    {
        dataField: "createdAt",
        text: "Data richiesta",
        sort: true
    },
    {
        dataField: "release.status",
        text: "Stato richiesta"
    },
    {
        dataField: "registerUrlStatus",
        text: "Pubblica Laboratorio",
    },
    {
        dataField: "registerUrl",
        text: "URL per la registrazione",
    },
    {
        dataField: "labDetails",
        text: "Dettaglio Laboratorio"
    }

];

function PubLabPage(props) {
    const [labData, setLabData] = useState();


    useEffect(() => {
        function convertDate(inputFormat) {
            function pad(s) { return (s < 10) ? '0' + s : s; }
            var d = new Date(inputFormat)
            return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
        };

        setTimeout(async () => {
            await fetch(process.env.REACT_APP_URL_GET_PERSONAL_LAB, {
                credentials: 'include',
                method: "GET"
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    data.forEach(data => {
                        if (data.release.status === "active") {
                            data.release.status = <ClipLoader
                                size={15}
                                color={"#a71e3b"}
                                loading={true}
                            />
                        }

                        data.createdAt = convertDate(data.createdAt);

                        data.registerUrlStatus = <SwitchPubLab
                            _id={data._id}
                            registerUrlStatus={data.registerUrlStatus}
                        />;

                        data.registerUrl = process.env.REACT_APP_URL_REGISTER_LAB_FRONTEND + "/" +
                            data.registerUrl;

                        data.labDetails = <Link to={{
                            pathname: 'PubLabDettPage',
                            labProps: data,
                            persProps: props
                        }}>Dettaglio</Link>  ;

                    });

                    setLabData(data);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });

    }, [props]);


    var ret = (
        <React.Fragment>
            <h2 className="title-page">Pubblicazione Laboratori Virtuali</h2>
            <p>Pagina di amministrazione dei Laboratori Virtuali</p>
            <BootstrapTable
                keyField="email"
                data={labData}
                columns={labColumns}
                striped
                hover
                condensed
                bordered={false}
            />

        </React.Fragment>
    );

    if (labData)
        return ret;
    else
        return null;


}

export default PubLabPage;
