import React from 'react';
import Form from 'react-bootstrap/Form';
import AsyncSelect from 'react-select/async'

function SelectSimTelNumber(props) {
    var ret;
    var mySel;

    const fetchTelNumber = (inputValue, callback) => {
        const tempArray = [];
        var formBody = [];
        const payload = {
            idSim: props.idSim,
            qrCode: props.qrCode,
            telNumber: inputValue
        }
        setTimeout(async () => {
            formBody.push("payload=" + JSON.stringify(payload));
            await fetch(process.env.REACT_APP_URL_KITDATA_SIM_TELNUMBER, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    data.forEach((element) => {
                        tempArray.push({
                            label: `${element.telNumber}`,
                            value:
                            {
                                iccd: `${element.iccd}`,
                                telNumber: `${element.telNumber}`
                            }
                        });
                    });
                    callback(filterData(inputValue, tempArray));
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }

    function filterData(inputValue, myData) {
        return myData.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    function onSearchChangeTelNumber(selectedOption) {
        if (selectedOption) {
            props.setSerialNumber(selectedOption.value.iccd);
            props.setTelNumber(selectedOption.value.telNumber);
        }
    };

    var myPlaceHolder;
    if (props.telNumber) {
        myPlaceHolder = props.telNumber
    } else {
        myPlaceHolder = "Numero linea SIM Dati"
    }


    mySel = (
        <React.Fragment>
            <AsyncSelect
                value=""
                placeholder={myPlaceHolder}
                loadOptions={fetchTelNumber}
                onChange={(e) => {
                    onSearchChangeTelNumber(e);
                }}
                components={{ DropdownIndicator: () => null }}
                noOptionsMessage={() => "Ricerca Numero..."}
            />
            <input
                tabIndex={-1}
                autoComplete="off"
                style={{ opacity: 0, height: 0 }}
                required={true}
                value={props.telNumber}
            />
        </React.Fragment>
    );


    ret = (
        <React.Fragment>
            <Form.Label>Numero linea SIM Dati</Form.Label>
            <div>
                {mySel}
            </div>

        </React.Fragment>
    )

    return ret;

}


export default SelectSimTelNumber;