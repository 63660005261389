import React from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import VoucherDoc from './VoucherDoc';
import { pdf } from '@react-pdf/renderer';
import VoucherBarcode from './VoucherBarcode';
var QRCode = require('qrcode.react');


function ReqVoucherConfirm(props) {
    var ret;

    const saveBlob = (blob, filename) => {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const savePdf = async (document, filename) => {
        saveBlob(await pdf(document).toBlob(), filename);
    };


    const saveVoucher = () => {
        const barCodeCanvas = document.querySelector('canvas.barCode');
        const qrCodeCanvas = document.querySelector('canvas.qrCode');
        //const qrCodeCanvas = document.getElementById("barCode");

        const barCodeDataUri = barCodeCanvas.toDataURL('image/jpg', 1);
        const qrCodeDataUri = qrCodeCanvas.toDataURL('image/jpg', 1);
        savePdf(<VoucherDoc
            barCodeDataUri={barCodeDataUri}
            qrCodeDataUri={qrCodeDataUri}
            stuMatricola={props.stuMatricola}
            apiAuthName={props.apiAuthName}
            apiAuthSurname={props.apiAuthSurname}
        />, "UnimibVoucher" + props.stuMatricola + ".pdf")
    }

    if (!props.isSubmitted) {
        ret = (
            <React.Fragment>
                <Button variant="primary" type="submit" >
                    Submit
                </Button>
            </React.Fragment>
        );
    } else {
        var qrCodeLink = process.env.REACT_APP_URL_CONSUME_VOUCHER
            + props.idVoucher + "/chk/"
            + props.qrCode;

        var barCodes = (
            <React.Fragment>
                <VoucherBarcode
                    value={props.idVoucher}
                />
                <QRCode
                    className="qrCode"
                    value={qrCodeLink}
                    size={300}
                    level="H"
                />
            </React.Fragment>
        );

        switch (props.statusRequest) {
            case "success":
                ret = (
                    <React.Fragment>
                        <Alert variant="success">

                            <Alert.Heading>Voucher emesso successo!</Alert.Heading>
                            <p>
                                Il Voucher è stato generato.
                            </p>
                            {barCodes}
                        </Alert>
                        <Button href="/">Torna alla Home Page</Button>{' '}
                        <Button onClick={saveVoucher}>Download Voucher</Button>

                    </React.Fragment>
                );
                break;
            case "dup":
                ret = (
                    <React.Fragment>
                        <Alert variant="danger">

                            <Alert.Heading>Un Voucher per l'utente indicato risulta già emesso!</Alert.Heading>
                            <p>
                                Il Voucher è già stato emesso.
                            </p>
                            {barCodes}
                        </Alert>
                        <Button href="/">Torna alla Home Page</Button>{' '}
                        <Button onClick={saveVoucher}>Download Voucher</Button>
                    </React.Fragment>
                );
                break;
            case "dupspent":
                ret = (
                    <React.Fragment>
                        <Alert variant="danger">
                            <Alert.Heading>Un Voucher per l'utente indicato risulta già utilizzato!</Alert.Heading>
                            <p>
                                Il Voucher è già stato utilizzato.
                            </p>
                        </Alert>
                        <Button href="/">Torna alla Home Page</Button>{' '}
                    </React.Fragment>
                );
                break;
            default:
                ret = (
                    <React.Fragment>
                        <Alert variant="danger">

                            <Alert.Heading>Si è verificato un errore!</Alert.Heading>
                            <p>
                                Contattare l'assistenza.
                            </p>
                        </Alert>
                        <Button href="/">Torna alla Home Page</Button>
                    </React.Fragment>
                );
        }

    }

    return ret;
}

export default ReqVoucherConfirm;