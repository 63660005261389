import React, { useState, useEffect } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ApplySimConfirm from './ApplySimConfirm';
import SelectRouter from './SelectRouter';
import SelectSimIccd from './SelectSimIccd';
import SelectSimTelNumber from './SelectSimTelNumber';
import Alert from 'react-bootstrap/Alert';
import ReCAPTCHA from "react-google-recaptcha";

function ApplySimPage(props) {
    const [isLoading, setIsLoading] = useState(1);
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [statusSim, setStatusSim] = useState();
    const [email, setEmail] = useState();
    const [matricola, setMatricola] = useState();
    const [nome, setNome] = useState();
    const [cognome, setCognome] = useState();
    const [codFis, setCodFis] = useState();
    const [dtCreazioneSim, setDtCreazioneSim] = useState();
    const [isDomicilio, setisDomicilio] = useState();

    // dati form
    const [imei, setImei] = useState();
    const [serialNumber, setSerialNumber] = useState();
    const [telNumber, setTelNumber] = useState();

    const [agreementChecked, setAgreementChecked] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState();

    var myHome = null;
    var dataInputSpent;
    var dataInput;
    var formTitle;
    var labelCheckbox;
    var confirmForm;



    useEffect(() => {
        const fetchData = () => {
            var formBody = [];
            const payload = {
                idSim: props.checkedIdSim,
                qrCode: props.checkedQrCode
            }
            setTimeout(async () => {
                setIsLoading(1);
                formBody.push("payload=" + JSON.stringify(payload));
                await fetch(process.env.REACT_APP_URL_SANITIZE_SIM, {
                    credentials: 'include',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: formBody
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        if (data) {
                            //console.log(JSON.stringify(data));
                            setEmail(data.email);
                            setMatricola(data.matricola);
                            setNome(data.nome);
                            setCognome(data.cognome);
                            setCodFis(data.codFis);
                            setStatusSim(data.stato);
                            setDtCreazioneSim(toDateHour(data.dtCreazione));
                            setisDomicilio(data.isDomicilio);
                            if (data.dettUtilizzo) {
                                if (data.dettUtilizzo.imei)
                                    setImei(data.dettUtilizzo.imei);
                                if (data.dettUtilizzo.serialNumber)
                                    setSerialNumber(data.dettUtilizzo.serialNumber);
                                if (data.dettUtilizzo.telNumber)
                                    setTelNumber(data.dettUtilizzo.telNumber);
                            }
                            setIsLoading(0);
                        } else {
                            setIsLoading(0);
                        }
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });
            });

        }
        fetchData();
    }, [props.checkedIdSim, props.checkedQrCode]);


    function toDateHour(dateStr) {
        const newDate = new Date(dateStr);
        const dtOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

        var newDateStr = newDate.toLocaleDateString('it-IT', dtOptions);
        var newTimeStr = newDate.toLocaleTimeString('it-IT');
        var ret = "";

        return ret.concat(newDateStr, ' ', newTimeStr);

        /*
        var ma = dateStr.split("T")
        var parts = ma[0].split("-")
        var hr = ma[1].split(":")
        var ret = ""
        return ret.concat(parts[2], '/', parts[1], '/', parts[0], ' ore ', hr[0], ':', hr[1])
        */
    }

    const onSubmit = (e) => {
        e.preventDefault();

        var formBody = [];
        const payload = {
            idSim: props.checkedIdSim,
            qrCode: props.checkedQrCode,
            imei: imei,
            serialNumber: serialNumber,
            telNumber: telNumber
        }
        setTimeout(async () => {
            setIsLoading(1);
            formBody.push("payload=" + JSON.stringify(payload));
            var myUrl;
            setRecaptchaToken();

            switch (statusSim) {
                case "visited":
                case "created":
                    myUrl = process.env.REACT_APP_URL_SPEND_SIM;
                    break;
                case "spent":
                    myUrl = process.env.REACT_APP_URL_CONFIRM_SIM;
                    break;
                default:
                    myUrl = process.env.REACT_APP_URL_CONFIRM_SIM;
            }

            await fetch(myUrl, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    console.log(JSON.stringify(data));
                    setIsSubmitted(1);
                    setIsLoading(0);
                    setStatusSim(data.stato);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }

    const toggleAgreement = () => {
        if (agreementChecked) {
            setAgreementChecked(false);
        } else {
            setAgreementChecked(true);
        }
    };

    const imeiHandler = (event) => {
        setImei(event.target.value);
    }
    const serialNumberHandler = (event) => {
        setSerialNumber(event.target.value);
    }

    const telNumberHandler = (event) => {
        setTelNumber(event.target.value);
    }

    const onChangeRecaptcha = (value) => {
        setRecaptchaToken(value);
    }


    if (email) {
        // Condizioni sul domicilio
        if (isDomicilio === 1) {
            if (statusSim === "confirmed" || statusSim === 'active') {
                formTitle = (
                    <React.Fragment>
                        <h2>Il modulo è stato confermato</h2>
                        <p>Informazioni relative alla richiesta effettuata in data {dtCreazioneSim}</p>
                    </React.Fragment>
                );
            } else {
                formTitle = (
                    <React.Fragment>
                        <h2>Modulo da compilare a cura dello studente</h2>
                        <p>Informazioni relative alla richiesta effettuata in data {dtCreazioneSim}</p>
                    </React.Fragment>
                );
                labelCheckbox = "Con il seguente checkbox si conferma di aver inserito i dati corrispondenti a SIM Dati e Router ricevuti";
            }
        } else {
            if (statusSim === "confirmed" || statusSim === 'active') {
                formTitle = (
                    <React.Fragment>
                        <h2>Il modulo è stato confermato dal Centro TIM</h2>
                        <p>Informazioni relative alla richiesta effettuata in data {dtCreazioneSim}</p>
                    </React.Fragment>
                );
            } else {
                formTitle = (
                    <React.Fragment>
                        <h2>Modulo da compilare a cura dal Centro TIM</h2>
                        <p>Informazioni relative alla richiesta effettuata in data {dtCreazioneSim}</p>
                    </React.Fragment>
                );
                labelCheckbox = "Con il seguente checkbox si conferma di aver inserito i dati corrispondenti a SIM Dati e Router consegnati.";
            }
        }

        // Condizioni sullo stato SIM
        if (statusSim === 'spent') {
            dataInputSpent = (
                <React.Fragment>
                    <Form.Group>
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Dati del Router</h5>

                                <Row>
                                    <Col>
                                        <SelectRouter
                                            setImei={setImei}
                                            imei={imei}
                                            isDomicilio={isDomicilio}
                                            imeiHandler={imeiHandler}
                                            idSim={props.checkedIdSim}
                                            qrCode={props.checkedQrCode}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Text className="text-muted">Il codice IMEI si trova sull'etichetta posta nel vano interno del Router accessibile rimuovendo la scocca posteriore e la batteria SIM, ad esempio:</Form.Text>
                                        <img
                                            style={{ height: '85px', width: '150px' }}
                                            src={require('./img/imei_router.jpg')} alt="Esempio IMEI Router" />
                                    </Col>
                                </Row>



                            </div>
                        </div>
                        <br />
                        <div className="card">
                            <div className="card-body">
                                <Row>
                                    <Col>
                                        <h5 className="card-title">Dati della SIM</h5>
                                        <SelectSimTelNumber
                                            setSerialNumber={setSerialNumber}
                                            setTelNumber={setTelNumber}
                                            telNumber={telNumber}
                                            isDomicilio={isDomicilio}
                                            telNumberHandler={telNumberHandler}
                                            idSim={props.checkedIdSim}
                                            qrCode={props.checkedQrCode}
                                        />
                                    </Col>
                                    <Col>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <SelectSimIccd
                                            setSerialNumber={setSerialNumber}
                                            setTelNumber={setTelNumber}
                                            serialNumber={serialNumber}
                                            isDomicilio={isDomicilio}
                                            serialNumberHandler={serialNumberHandler}
                                            idSim={props.checkedIdSim}
                                            qrCode={props.checkedQrCode}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Text className="text-muted">Il codice ICCD si trova sulla SIM, ad esempio:</Form.Text>
                                        <img
                                            style={{ height: '85px', width: '150px' }}
                                            src={require('./img/sim_new.png')} alt="Esempio ICCD SIM" />
                                    </Col>
                                </Row>

                            </div>
                        </div>
                        <br />
                    </Form.Group>
                </React.Fragment>

            );


            confirmForm = (
                <Form onSubmit={onSubmit}>
                    <Alert variant="warning">
                        <Alert.Heading>Ricontrollare i dati inseriti della SIM Dati e Router prima di confermare definitivamente</Alert.Heading>
                        <p>
                            L'errata compilazione del presente modulo comporta l'annullamento della SIM.
                        </p>
                    </Alert>

                    {dataInputSpent}

                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_URL_RECAPTCHA_KEY}
                        onChange={onChangeRecaptcha}
                    />

                    <div>
                        <ApplySimConfirm
                            isSubmitted={isSubmitted}
                            statusSim={statusSim}
                            stuMatricola={props.stuMatricola}
                            recaptchaToken={recaptchaToken}
                        />
                        <br />
                        <ClipLoader
                            size={35}
                            color={"#a71e3b"}
                            loading={isLoading}
                        />
                    </div>
                </Form>

            )

            dataInput = null;

        } else if (statusSim === 'created' || statusSim === 'visited') {
            dataInput = (
                <React.Fragment>
                    <Form.Label>I seguenti dati sono necessari per procedere con l'attivazione di SIM e Router.
                    L'errata compilazione comporta l'annullamento della SIM.
                    </Form.Label>

                    <Form.Group>
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Dati del Router</h5>
                                <Row>
                                    <Col>
                                        <SelectRouter
                                            setImei={setImei}
                                            imei={imei}
                                            isDomicilio={isDomicilio}
                                            imeiHandler={imeiHandler}
                                            idSim={props.checkedIdSim}
                                            qrCode={props.checkedQrCode}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Text className="text-muted">Il codice IMEI si trova sull'etichetta posta nel vano interno del Router accessibile rimuovendo la scocca posteriore e la batteria SIM, ad esempio:</Form.Text>
                                        <img
                                            style={{ height: '85px', width: '150px' }}
                                            src={require('./img/imei_router.jpg')} alt="Esempio IMEI Router" />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <br />
                        <div className="card">
                            <div className="card-body">

                                <Row>
                                    <Col>
                                        <h5 className="card-title">Dati della SIM</h5>
                                        <SelectSimTelNumber
                                            setSerialNumber={setSerialNumber}
                                            setTelNumber={setTelNumber}
                                            telNumber={telNumber}
                                            isDomicilio={isDomicilio}
                                            telNumberHandler={telNumberHandler}
                                            idSim={props.checkedIdSim}
                                            qrCode={props.checkedQrCode}
                                        />
                                    </Col>
                                    <Col>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <SelectSimIccd
                                            setSerialNumber={setSerialNumber}
                                            setTelNumber={setTelNumber}
                                            serialNumber={serialNumber}
                                            isDomicilio={isDomicilio}
                                            serialNumberHandler={serialNumberHandler}
                                            idSim={props.checkedIdSim}
                                            qrCode={props.checkedQrCode}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Text className="text-muted">Il codice ICCD si trova sulla SIM, ad esempio:</Form.Text>
                                        <img
                                            style={{ height: '85px', width: '150px' }}
                                            src={require('./img/sim_new.png')} alt="Esempio ICCD SIM" />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <br /><br />
                    </Form.Group>


                    <Form.Group id="formGridCheckbox">
                        <Form.Check
                            required
                            onChange={toggleAgreement}
                            type="checkbox"
                            id="agreement"
                            label={labelCheckbox}
                        />
                        <br />
                    </Form.Group>

                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_URL_RECAPTCHA_KEY}
                        onChange={onChangeRecaptcha}
                    />

                    <br />

                    <div>
                        <ApplySimConfirm
                            isSubmitted={isSubmitted}
                            statusSim={statusSim}
                            stuMatricola={props.stuMatricola}
                            recaptchaToken={recaptchaToken}
                        />
                        <br />
                        <ClipLoader
                            size={35}
                            color={"#a71e3b"}
                            loading={isLoading}
                        />
                    </div>


                </React.Fragment >

            );

        } else if (statusSim === 'confirmed' || statusSim === 'active') {


            dataInput = (
                <React.Fragment>
                    <Form.Label>I seguenti dati sono stati confermati e non sono modificabili </Form.Label>

                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Dati del Router</h5>
                            <Row>
                                <Col>
                                    <Form.Label>Codice IMEI</Form.Label>
                                    <Form.Control style={{ width: "100%" }}
                                        type="text"
                                        placeholder={`${imei}`}
                                        readOnly
                                    />
                                </Col>
                                <Col>
                                    <Form.Text className="text-muted">Il codice IMEI si trova sull'etichetta posta nel vano interno del Router accessibile rimuovendo la scocca posteriore e la batteria SIM, ad esempio:</Form.Text>
                                    <img
                                        style={{ height: '85px', width: '150px' }}
                                        src={require('./img/imei_router.jpg')} alt="Esempio IMEI Router" />
                                </Col>
                            </Row>




                        </div>
                    </div>
                    <br />
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Dati della SIM</h5>
                            <Row>
                                <Col>
                                    <Form.Label>Numero linea SIM Dati</Form.Label>
                                    <Form.Control style={{ width: "100%" }}
                                        type="text"
                                        placeholder={`${telNumber}`}
                                        readOnly
                                    />
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <Form.Label>Codice ICCD SIM</Form.Label>
                                    <Form.Control style={{ width: "100%" }}
                                        type="text"
                                        placeholder={`${serialNumber}`}
                                        readOnly
                                    />
                                </Col>
                                <Col>
                                    <Form.Text className="text-muted">Il codice ICCD si trova sulla SIM, ad esempio:</Form.Text>
                                    <img
                                        style={{ height: '85px', width: '150px' }}
                                        src={require('./img/sim_new.png')} alt="Esempio ICCD SIM" />
                                </Col>
                            </Row>

                        </div>
                    </div>

                    <br />

                    <div>
                        <ApplySimConfirm
                            isSubmitted={isSubmitted}
                            statusSim={statusSim}
                            stuMatricola={props.stuMatricola}
                            recaptchaToken={recaptchaToken}
                        />
                        <br />
                        <ClipLoader
                            size={35}
                            color={"#a71e3b"}
                            loading={isLoading}
                        />
                    </div>

                </React.Fragment >

            );

        }

        // Pagina  con variabili: titolo, dataInput e confirmForm
        myHome = (
            <React.Fragment>
                {formTitle}
                <Form onSubmit={onSubmit}>
                    <Form.Group style={{ width: "100%" }}>
                        <Form.Label>Nome titolare</Form.Label>
                        <Form.Control style={{ width: "100%" }}
                            type="text"
                            placeholder={`${nome}`}
                            readOnly
                        />
                        <br />
                        <Form.Label>Cognome titolare</Form.Label>
                        <Form.Control style={{ width: "100%" }}
                            type="text"
                            placeholder={`${cognome}`}
                            readOnly
                        />
                        <br />
                        <Form.Label>Codice Fiscale titolare</Form.Label>
                        <Form.Control style={{ width: "100%" }}
                            type="text"
                            placeholder={`${codFis}`}
                            readOnly
                        />
                        <br />
                        <Form.Label>E-mail titolare</Form.Label>
                        <Form.Control style={{ width: "100%" }}
                            type="text"
                            placeholder={`${email}`}
                            readOnly
                        />
                        <br />
                        <Form.Label>Matricola titolare</Form.Label>
                        <Form.Control style={{ width: "100%" }}
                            type="text"
                            placeholder={`${matricola}`}
                            readOnly
                        />
                        <br />
                    </Form.Group>


                    {dataInput}


                </Form>

                {confirmForm}



            </React.Fragment>
        );

    } else {
        myHome = (
            <React.Fragment>
                <ClipLoader
                    size={35}
                    color={"#a71e3b"}
                    loading={isLoading}
                />
            </React.Fragment>
        );
    }



    return myHome;
}

export default ApplySimPage;