import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import L0RichiesteTotali from './L0RichiesteTotali';
import L1Posta from './L1Posta';
import L2Attivazioni from './L2Attivazioni';
import L3UploadAttivate from './L3UploadAttivate';
import L4Attivate from './L4Attivate';


function TimConsollePage() {
    var ret;

    ret = (
        <React.Fragment >
            <br />
            <Tabs defaultActiveKey="l0" id="tim-consolle">
                <Tab eventKey="l0" title="L0 - Richieste totali">
                    <L0RichiesteTotali />
                </Tab>
                <Tab eventKey="l1" title="L1 - Consegna a mezzo posta">
                    <L1Posta />
                </Tab>
                <Tab eventKey="l2" title="L2 - Attivazioni">
                    <L2Attivazioni />
                </Tab>
                <Tab eventKey="l3" title="L3 - Upload attivate">
                    <L3UploadAttivate />
                </Tab>
                <Tab eventKey="l4" title="L4 - Attivate">
                    <L4Attivate />
                </Tab>
            </Tabs>

            <br />
            <br />
        </React.Fragment>




    );

    return ret;


}

export default TimConsollePage;