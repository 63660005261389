import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import AsyncSelect from 'react-select/async'

function SelectAd(props) {
  const [selOpt, setSelOpt] = useState();
  const [numAd, setNumAd] = useState(0);
  const [personalAd, setPersonalAd] = useState();

  function fetchDefaultData() {
    const tempArray = [];
    setTimeout(async () => {
      await fetch(process.env.REACT_APP_URL_COPER, {
        credentials: 'include',
        method: "GET"
      })
        .then((resp) => {
          return resp.json()
        })
        .then((data) => {
          data.forEach((element) => {
            tempArray.push(
              {
                afId: `${element.afId}`,
                codIns: `${element.codIns}`,
                desIns: `${element.desIns}`,
                codMod: `${element.codMod}`,
                desMod: `${element.desMod}`,
              });
          });
        })
        .then(() => {
          setNumAd(tempArray.length);
          setPersonalAd(tempArray);
        })
        .catch((error) => {
          console.log(error, "Errore!")
        });
    });
  }


  function fetchData(inputValue, callback) {
    const tempArray = [];
    setTimeout(async () => {
      await fetch(process.env.REACT_APP_URL_COPER, {
        credentials: 'include',
        method: "GET"
      })
        .then((resp) => {
          return resp.json()
        })
        .then((data) => {
          data.forEach((element) => {
            tempArray.push({
              label: `Insegnamento: [${element.codIns}] ${element.desIns}` +
                ` - Modulo: [${element.codMod}] ${element.desMod}`,
              value: `${element.afId}`
            });
          });
          callback(filterData(inputValue, tempArray));
        })
        .then(() => {
          setNumAd(tempArray.length);
        })
        .catch((error) => {
          console.log(error, "Errore!")
        });
    });
  }

  function filterData(inputValue, myData) {
    return myData.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  function onSearchChange(selectedOption) {
    if (selectedOption) {
      setSelOpt({
        selectedOption
      });
    }
  };

  useEffect(() => {
    fetchDefaultData();
  }, []);

  useEffect(() => {
    if (selOpt) {
      const myElem = personalAd.find(
        (element) => {
          var aux = null;
          if (element.afId === selOpt.selectedOption.value)
            aux = element;
          return aux;
        }
      );

      props.adSelected(myElem);
    }
  }, [selOpt, personalAd, props])


  const labOtherActivitiesHandler = (event) => {
    props.labOtherActivitiesSelected(event.target.value);
  }

  var ret;

  if (numAd > 0) {
    ret = (
      <React.Fragment >
        <Form.Label>Attività didattica</Form.Label>
        <Form.Text className="text-muted">
          Dati estratti da U-Gov Didattica.
        </Form.Text>
        <div>
          <AsyncSelect
            //value={this.state.selectedOption.value}
            loadOptions={fetchData}
            onChange={(e) => {
              onSearchChange(e);
            }}
            defaultOptions={true}
          />
          {(
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{ opacity: 0, height: 0 }}
              value={selOpt}
              required={true}
            />
          )}
        </div>
      </React.Fragment >
    );

  } else {
    ret = (
      <React.Fragment >
        <Form.Label>Attività didattica </Form.Label>
        <Form.Text className="text-muted">
          Non sono state trovate attività associate al suo account su U-Gov Didattica.
          Specificare le finalità del Laboratorio Virtuale,
          la richiesta sarà sottoposta a procedura di approvazione.
      </Form.Text>
        <div>
          <Form.Control style={{ width: "100%" }}
            as="textarea"
            rows="3"
            maxlength="2000"
            placeholder="Inserire le finalità del Laboratorio"
            required={true}
            onChange={labOtherActivitiesHandler}
          />
        </div>
      </React.Fragment >
    );
  }

  return ret;



}

export default SelectAd;
