import React from 'react';
import Form from 'react-bootstrap/Form';


function ApplyVoucherResellerSpecializedPage(props) {
    var ret;


    switch (props.reseller) {
        case "mediaworld":
            ret = (
                <React.Fragment>
                    <img src={require('./img/mv.png')} alt="Mediaworld" />
                    <br /><br />
                    <Form.Label>Codice Filiale</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        onChange={props.mediaworldCodiceFilialeHandler}
                        placeholder="Codice Filiale"
                        required
                    />
                    <br />
                    <Form.Label>Numero Transazione</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        onChange={props.mediaworldNumTransazioneHandler}
                        placeholder="Numero Transazione"
                        required
                    />
                    <br />
                </React.Fragment>
            );
            break;

        case "cec":
            ret = (
                <React.Fragment>
                    <img src={require('./img/cec_logo.png')} alt="cec" />
                    <br /><br />
                    <Form.Label>Codice Filiale</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        onChange={props.cecCodiceFilialeHandler}
                        placeholder="Codice Filiale"
                        required
                    />
                    <br />
                    <Form.Label>Numero Transazione</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        onChange={props.cecNumTransazioneHandler}
                        placeholder="Numero Transazione"
                        required
                    />
                    <br />
                </React.Fragment>
            );
            break;

        case "medcomputer":
            ret = (
                <React.Fragment>
                    <img src={require('./img/medcomputer.png')} alt="Med Computer" />
                    <br /><br />
                    <Form.Label>Codice Filiale</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        onChange={props.medComputerCodiceFilialeHandler}
                        placeholder="Codice Filiale"
                        required
                    />
                    <br />
                    <Form.Label>Numero Transazione</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        onChange={props.medComputerNumTransazioneHandler}
                        placeholder="Numero Transazione"
                        required
                    />
                    <br />
                </React.Fragment>
            );
            break;


        default:
            ret = null;
    }


    return ret;
}

export default ApplyVoucherResellerSpecializedPage;