import React from 'react';
import Can from "../Can";
import VoucherstudentPage from './VoucherstudentPage';

function Voucherstudent(props) {
    var myHome;

    if (!props.apiAuthGroups) {
        myHome = (
            <React.Fragment>
                <h2>Non autorizzato</h2>
            </React.Fragment>
        )
    } else {
        myHome = (
            <Can
                role={props.apiAuthGroups}
                perform="voucherstudent-page:visit"
                yes={() => (
                    <VoucherstudentPage
                        apiAuthResponse={props.apiAuthResponse}
                        apiAuthName={props.apiAuthName}
                        apiAuthSurname={props.apiAuthSurname}
                        apiAuthGroups={props.apiAuthGroups}
                        stuMatricola={props.stuMatricola}
                        stuCodFis={props.stuCodFis}
                        stuIndiRes={props.stuIndiRes}
                        stuIndiDom={props.stuIndiDom}
                    />
                )}
                no={() => (null)}
            />


        )
    }

    return myHome;
}

export default Voucherstudent;