import React from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

function ReqLabConfirm(props) {
    var ret;
    if (props.isSubmitble) {
        if (!props.isSubmitted) {
            ret = (
                <React.Fragment>
                    <Button variant="primary" type="submit" >
                        Submit
                    </Button>
                </React.Fragment>
            );
        } else {
            if (props.statusRequest === "success") {
                ret = (
                    <React.Fragment>
                        <Alert variant="success">

                            <Alert.Heading>Richiesta Laboratorio Virtuale acquisita con successo!</Alert.Heading>
                            <p>
                                Il Laboratorio Virtuale richiesto è in lavorazione. E' possibile consultare lo stato di avanzamento
                            della richiesta da {' '}  <Alert.Link href="/PubLab">Pubblicazione Laboratori</Alert.Link>.
                        </p>

                        </Alert>
                        <Button href="/">Torna alla Home Page</Button>
                    </React.Fragment>
                );
            } else {
                ret = (
                    <React.Fragment>
                        <Alert variant="danger">

                            <Alert.Heading>Si è verificato un errore!</Alert.Heading>
                            <p>
                                Contattare l'assistenza.
                    </p>
                        </Alert>
                        <Button href="/">Torna alla Home Page</Button>
                    </React.Fragment>
                );

            };
        }
    } else {
        ret = (
            <React.Fragment>
                <Button variant="primary" type="submit" disabled>
                    Submit
                </Button>
            </React.Fragment>
        );
    }
    return ret;
}

export default ReqLabConfirm;