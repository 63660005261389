import React, { useState, useEffect } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ApplyVoucherConfirm from './ApplyVoucherConfirm';
import ApplyVoucherResellerSpecializedPage from './ApplyVoucherResellerSpecializedPage';
import ReCAPTCHA from "react-google-recaptcha";

function ApplyVoucherPage(props) {
    const [isLoading, setIsLoading] = useState(1);
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [statusVoucher, setStatusVoucher] = useState();
    const [email, setEmail] = useState();
    const [matricola, setMatricola] = useState();
    const [nome, setNome] = useState();
    const [cognome, setCognome] = useState();
    const [codFis, setCodFis] = useState();
    const [dtCreazioneVoucher, setDtCreazioneVoucher] = useState();
    // dati form
    const [reseller, setReseller] = useState();
    const [mediaworldCodiceFiliale, setMediaworldCodiceFiliale] = useState();
    const [mediaworldNumTransazione, setMediaworldNumTransazione] = useState();
    const [cecCodiceFiliale, setCecCodiceFiliale] = useState();
    const [cecNumTransazione, setCecNumTransazione] = useState();
    const [medComputerCodiceFiliale, setMedComputerCodiceFiliale] = useState();
    const [medComputerNumTransazione, setMedComputerNumTransazione] = useState();        

    const [agreementChecked, setAgreementChecked] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState();

    var myHome = null;


    useEffect(() => {
        const fetchData = () => {
            var formBody = [];
            const payload = {
                idVoucher: props.checkedIdVoucher,
                qrCode: props.checkedQrCode
            }
            setTimeout(async () => {
                setIsLoading(1);
                formBody.push("payload=" + JSON.stringify(payload));
                await fetch(process.env.REACT_APP_URL_SANITIZE_VOUCHER, {
                    credentials: 'include',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: formBody
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        if (data) {
                            console.log(JSON.stringify(data));
                            setEmail(data.email);
                            setMatricola(data.matricola);
                            setNome(data.nome);
                            setCognome(data.cognome);
                            setCodFis(data.codFis);
                            setStatusVoucher(data.stato);
                            setDtCreazioneVoucher(toDateHour(data.dtCreazione));
                            setIsLoading(0);
                        } else {
                            setIsLoading(0);
                        }
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });
            });

        }
        fetchData();
    }, [props.checkedIdVoucher, props.checkedQrCode]);


    function toDateHour(dateStr) {
        const newDate = new Date(dateStr);
        const dtOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

        var newDateStr = newDate.toLocaleDateString('it-IT', dtOptions);
        var newTimeStr = newDate.toLocaleTimeString('it-IT');
        var ret = "";

        return ret.concat(newDateStr, ' ', newTimeStr);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        var formBody = [];
        const payload = {
            idVoucher: props.checkedIdVoucher,
            qrCode: props.checkedQrCode,
            mediaworldCodiceFiliale: encodeURIComponent(mediaworldCodiceFiliale),
            mediaworldNumTransazione: encodeURIComponent(mediaworldNumTransazione),
            cecCodiceFiliale: encodeURIComponent(cecCodiceFiliale),
            cecNumTransazione: encodeURIComponent(cecNumTransazione),
            medComputerCodiceFiliale: encodeURIComponent(medComputerCodiceFiliale),
            medComputerNumTransazione: encodeURIComponent(medComputerNumTransazione)
        }
        setTimeout(async () => {
            setIsLoading(1);
            formBody.push("payload=" + JSON.stringify(payload));
            await fetch(process.env.REACT_APP_URL_SPEND_VOUCHER, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    console.log(JSON.stringify(data));
                    setIsSubmitted(1);
                    setIsLoading(0);
                    //setIdVoucher(data.idVoucher);
                    //setQrCode(data.qrCode);                    
                    setStatusVoucher(data.stato);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }


    const toggleAgreement = () => {
        if (agreementChecked) {
            setAgreementChecked(false);
        } else {
            setAgreementChecked(true);
        }
    };

    const mediaworldCodiceFilialeHandler = (event) => {
        setMediaworldCodiceFiliale(event.target.value);
    }
    const mediaworldNumTransazioneHandler = (event) => {
        setMediaworldNumTransazione(event.target.value);
    }

    const cecCodiceFilialeHandler = (event) => {
        setCecCodiceFiliale(event.target.value);
    }
    const cecNumTransazioneHandler = (event) => {
        setCecNumTransazione(event.target.value);
    }

    const medComputerCodiceFilialeHandler = (event) => {
        setMedComputerCodiceFiliale(event.target.value);
    }
    const medComputerNumTransazioneHandler = (event) => {
        setMedComputerNumTransazione(event.target.value);
    }        

    const onChangeRecaptcha = (value) => {
        setRecaptchaToken(value);
    }

    const onSelectResellerMediaworld = (value) => {
        setReseller("mediaworld");
    }

    const onSelectResellerCec = (value) => {
        setReseller("cec");
    }

    const onSelectResellerMedComputer = (value) => {
        setReseller("medcomputer");
    }

    // autenticazione basata su voucher
    if (email) {
        myHome = (
            <React.Fragment>
                <h2>Utilizzo del Voucher emesso in data {dtCreazioneVoucher}</h2>
                <Form onSubmit={onSubmit}>
                    <Form.Group style={{ width: "100%" }}>
                        <Form.Label>Nome titolare</Form.Label>
                        <Form.Control style={{ width: "100%" }}
                            type="text"
                            placeholder={`${nome}`}
                            readOnly
                        />
                        <br />
                        <Form.Label>Cognome titolare</Form.Label>
                        <Form.Control style={{ width: "100%" }}
                            type="text"
                            placeholder={`${cognome}`}
                            readOnly
                        />
                        <br />
                        <Form.Label>Codice Fiscale titolare</Form.Label>
                        <Form.Control style={{ width: "100%" }}
                            type="text"
                            placeholder={`${codFis}`}
                            readOnly
                        />
                        <br />
                        <Form.Label>E-mail titolare</Form.Label>
                        <Form.Control style={{ width: "100%" }}
                            type="text"
                            placeholder={`${email}`}
                            readOnly
                        />
                        <br />
                        <Form.Label>Matricola titolare</Form.Label>
                        <Form.Control style={{ width: "100%" }}
                            type="text"
                            placeholder={`${matricola}`}
                            readOnly
                        />
                        <br />
                    </Form.Group>

                    <Form.Text className="text">
                        <b>Dati da compilare a cura del rivenditore.</b>
                        <br />
                        <b>L'errata compilazione del presente modulo comporta l'annullamento del voucher.</b>
                    </Form.Text>
                    <br />
                    <Form.Group>
                        <DropdownButton
                            id="dropdown-basic-button"
                            title="Selezionare rivenditore"
                            variant="secondary"
                        >
                            <Dropdown.Item onClick={onSelectResellerMediaworld} id="mediaworld">Mediaworld</Dropdown.Item>
                            <Dropdown.Item onClick={onSelectResellerCec} id="cec">C&C Consulting</Dropdown.Item>
                            <Dropdown.Item onClick={onSelectResellerMedComputer} id="medComputer">Med Computer</Dropdown.Item>
                        </DropdownButton>
                        <br />

                        <ApplyVoucherResellerSpecializedPage
                            reseller={reseller}
                            mediaworldCodiceFilialeHandler={mediaworldCodiceFilialeHandler}
                            mediaworldNumTransazioneHandler={mediaworldNumTransazioneHandler}
                            cecCodiceFilialeHandler={cecCodiceFilialeHandler}
                            cecNumTransazioneHandler={cecNumTransazioneHandler}
                            medComputerCodiceFilialeHandler={medComputerCodiceFilialeHandler}
                            medComputerNumTransazioneHandler={medComputerNumTransazioneHandler}
                        />
                    </Form.Group>

                    <Form.Group id="formGridCheckbox">
                        <Form.Text className="text-muted">
                            Con il seguente checkbox si conferma di aver letto e accettare il regolamento.
                            </Form.Text>
                        <Form.Check
                            required
                            onChange={toggleAgreement}
                            type="checkbox"
                            id="agreement"
                            label="Acconsento al trattamento dei miei dati personali"
                        />
                        <br />
                    </Form.Group>

                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_URL_RECAPTCHA_KEY}
                        onChange={onChangeRecaptcha}
                    />
                    <br />

                    <div>
                        <ApplyVoucherConfirm
                            isSubmitted={isSubmitted}
                            statusVoucher={statusVoucher}
                            stuMatricola={matricola}
                            recaptchaToken={recaptchaToken}
                            reseller={reseller}
                            idVoucher={props.checkedIdVoucher}
                            qrCode={props.checkedQrCode}                          
                        />
                        <br />
                        <ClipLoader
                            size={35}
                            color={"#a71e3b"}
                            loading={isLoading}
                        />
                    </div>
                </Form>


            </React.Fragment>
        );
    } else {
        if (!isLoading) {
            myHome = (
                <React.Fragment>
                    <h2>Voucher non utilizzabile</h2>
                </React.Fragment>
            );
        } else {
            myHome = (
                <React.Fragment>
                    <ClipLoader
                        size={35}
                        color={"#a71e3b"}
                        loading={isLoading}
                    />
                </React.Fragment>
            );
        }
    }



    return myHome;
}

export default ApplyVoucherPage;