import React, { useState, useEffect } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import BootstrapTable from "react-bootstrap-table-next";
//import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import SelectRouter from '../sim4student/SelectRouter';
import SelectSimIccd from '../sim4student/SelectSimIccd';
import SelectSimTelNumber from '../sim4student/SelectSimTelNumber';

import '../../css/table-sort.css';


const columns = [
    {
        dataField: "email",
        text: "E-Mail",
        sort: true
    },
    {
        dataField: "matricola",
        text: "Matricola",
        sort: true
    },
    {
        dataField: "nome",
        text: "Nome",
        sort: true
    },
    {
        dataField: "cognome",
        text: "Cognome",
        sort: true
    },
    {
        dataField: "codFis",
        text: "Codice Fiscale",
        sort: true
    },
    {
        dataField: "stato",
        text: "Stato richiesta",
        sort: true
    },
    {
        dataField: "indirizzo",
        text: "Indirizzo",
        sort: true
    },
    {
        dataField: "isDomicilio",
        text: "Modalità consegna",
        sort: true
    },
    {
        dataField: "pdv_codPdv",
        text: "Codice Punto di Vendita",
        sort: true
    },
    {
        dataField: "pdv_desPdv",
        text: "Descrizione Punto di Vendita",
        sort: true
    },
    {
        dataField: "pdv_week",
        text: "Settimana ritiro",
        sort: true
    },
    {
        dataField: "router_imei",
        text: "Router IMEI",
        sort: true
    },
    {
        dataField: "sim_iccd",
        text: "SIM ICCD",
        sort: true
    },
    {
        dataField: "sim_telefono",
        text: "SIM Numero",
        sort: true
    },
    {
        dataField: "dtCreazione",
        text: "Data richiesta",
        sort: true
    },
    {
        dataField: "dtConfirm",
        text: "Data conferma",
        sort: true
    }
];


function GestioneKit() {
    const [isLoading, setIsLoading] = useState(1)
    const [dataKit, setDataKit] = useState([]);
    const [searchKit, setSearchKit] = useState("");
    const [chiave, setChiave] = useState("Selezionare chiave");
    const [showModal, setShowModal] = useState(false);
    const [isKeySelected, setIsKeySelected] = useState(false);
    const [kitPayload, setKitPayload] = useState({});
    const [imei, setImei] = useState();
    const [telNumber, setTelNumber] = useState();
    const [serialNumber, setSerialNumber] = useState();


    var ret;

    function convertDate(inputFormat) {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
    };

    const searchKitHandler = (kit) => {
        setSearchKit(kit.target.value);
    }

    const selectChiaveEmail = (e) => {
        e.preventDefault();
        setChiave("email");
        setIsKeySelected(true);
    }

    const selectChiaveIccd = (e) => {
        e.preventDefault();
        setChiave("dettUtilizzo.serialNumber");
        setIsKeySelected(true);
    }

    const selectChiaveTelNumber = (e) => {
        e.preventDefault();
        setChiave("dettUtilizzo.telNumber");
        setIsKeySelected(true);
    }

    const selectChiaveImei = (e) => {
        e.preventDefault();
        setChiave("dettUtilizzo.imei");
        setIsKeySelected(true);
    }

    const selectChiaveStato = (e) => {
        e.preventDefault();
        setChiave("stato");
        setIsKeySelected(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const handleDeleteModal = () => {
        setShowModal(false);
    }

    const handleSaveModal = () => {
        var formBody = [];
        var tempPayload = kitPayload;

        tempPayload.routerImei = imei;
        tempPayload.simIccd = serialNumber;
        tempPayload.simTelefono = telNumber;

        setKitPayload(tempPayload);    

        setTimeout(async () => {
            setIsLoading(1);

            console.log("save: " + JSON.stringify(kitPayload))
            formBody.push("payload=" + JSON.stringify(kitPayload));
            await fetch(process.env.REACT_APP_URL_KITDATA_ADMIN_ALTER_KIT, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });

            setIsLoading(0);
            setChiave("email");
            setSearchKit(kitPayload.email);
        });

        setShowModal(false);
    }

    const imeiHandler = () => {
        //        setShowModal(false);
    }

    const telNumberHandler = () => {
        //        setShowModal(false);
    }

    const serialNumberHandler = () => {
        //        setShowModal(false);
    }


    useEffect(() => {
        var formBody = [];
        var payload = {
            key: chiave,
            value: searchKit
        }

        function fetchData() {
            setTimeout(async () => {
                setIsLoading(1);

                formBody.push("payload=" + JSON.stringify(payload));
                await fetch(process.env.REACT_APP_URL_KITDATA_ADMIN_SEARCH_KIT, {
                    credentials: 'include',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: formBody
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        data.forEach(data => {
                            data.pdv_codPdv = data.pdv.codPdv;
                            data.pdv_desPdv = data.pdv.desPdv;
                            data.pdv_week = data.pdv.week;
                            data.router_imei = data.dettUtilizzo.imei;
                            data.sim_iccd = data.dettUtilizzo.serialNumber;
                            data.sim_telefono = data.dettUtilizzo.telNumber;
                            data.dtCreazione = convertDate(data.dtCreazione);
                            data.dtConfirm = convertDate(data.dtConfirm);
                            data.isDomicilio = (data.isDomicilio ? 'Posta' : 'Centro TIM');
                        });

                        setDataKit(data);
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });

                setIsLoading(0);
            });

        }

        setDataKit([])

        fetchData();

    }, [searchKit, chiave]);


    const onSubmit = (e) => {
        e.preventDefault();
        //var formBody = [];
        //var url;

    }


    const modalKit = () => {
        if (!showModal) return;

        var retModal;

        retModal = (
            <Modal
                className={'modal-dialog-centered'}
                show={showModal}
                onHide={handleCloseModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Modifica Kit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <Form.Group controlId="ModalForm.ScheduleEvent1">
                            <Form.Label>E-Mail titolare</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder={kitPayload.email}
                                readOnly
                            />
                            <Form.Label>Nome titolare</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={kitPayload.nome}
                                readOnly
                            />
                            <Form.Label>Cognome titolare</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={kitPayload.cognome}
                                readOnly
                            />
                            <Form.Label>Codice Fiscale</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={kitPayload.codFis}
                                readOnly
                            />

                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Dati del Router</h5>

                                    <Row>
                                        <Col>
                                            <SelectRouter
                                                setImei={setImei}
                                                imei={imei}
                                                isDomicilio={0}
                                                imeiHandler={imeiHandler}
                                                idSim={kitPayload.idSim}
                                                qrCode={kitPayload.qrCode}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Text className="text-muted">Il codice IMEI si trova sull'etichetta posta nel vano interno del Router accessibile rimuovendo la scocca posteriore e la batteria SIM, ad esempio:</Form.Text>
                                            <img
                                                style={{ height: '85px', width: '150px' }}
                                                src={require('../sim4student/img/imei_router.jpg')} alt="Esempio IMEI Router" />
                                        </Col>
                                    </Row>
                                </div>

                                <div className="card">
                                    <div className="card-body">
                                        <Row>
                                            <Col>
                                                <h5 className="card-title">Dati della SIM</h5>
                                                <SelectSimTelNumber
                                                    setSerialNumber={setSerialNumber}
                                                    setTelNumber={setTelNumber}
                                                    telNumber={telNumber}
                                                    isDomicilio={0}
                                                    telNumberHandler={telNumberHandler}
                                                    idSim={kitPayload.idSim}
                                                    qrCode={kitPayload.qrCode}
                                                />
                                            </Col>
                                            <Col>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <SelectSimIccd
                                                    setSerialNumber={setSerialNumber}
                                                    setTelNumber={setTelNumber}
                                                    serialNumber={serialNumber}
                                                    isDomicilio={0}
                                                    serialNumberHandler={serialNumberHandler}
                                                    idSim={kitPayload.idSim}
                                                    qrCode={kitPayload.qrCode}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Text className="text-muted">Il codice ICCD si trova sulla SIM, ad esempio:</Form.Text>
                                                <img
                                                    style={{ height: '85px', width: '150px' }}
                                                    src={require('../sim4student/img/sim_new.png')} alt="Esempio ICCD SIM" />
                                            </Col>
                                        </Row>

                                    </div>
                                </div>

                            </div>
                            <br />

                        </Form.Group>
                    </Form>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteModal}>
                        Annulla
                    </Button>
                    <Button variant="primary" onClick={handleSaveModal}>
                        Salva
                    </Button>
                </Modal.Footer>
            </Modal>
        );

        return retModal;

    }

    const rowEvents = {
        onClick: (e, row, rowIndex) => {

            var payload = {
                email: row.email,
                nome: row.nome,
                cognome: row.cognome,
                codFis: row.codFis,
                routerImei: row.router_imei,
                simIccd: row.sim_iccd,
                simTelefono: row.sim_telefono,
                idSim: row.idSim.toString(),
                qrCode: row.qrCode,
            };
            setImei(row.router_imei);
            setTelNumber(row.sim_telefono);
            setSerialNumber(row.sim_iccd);
            setKitPayload(payload);

            setShowModal(true);
        }
    }


    if (!dataKit) {
        ret = null;
    } else {

        ret = (
            <React.Fragment>
                {modalKit()}
                <h2 className="title-page">Kit SIM e Router richiesti </h2>
                <p>Ricerca di SIM e Router richiesti o attribuiti</p>
                <br /><br />

                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="searchRouter" style={{ width: "85%" }}>
                        <Form.Label>Ricerca</Form.Label>
                        <Form.Row>
                            <Col>
                                <DropdownButton id="dropdown-basic-button" title={"chiave"}>
                                    <Dropdown.Item onClick={selectChiaveEmail} >E-Mail</Dropdown.Item>
                                    <Dropdown.Item onClick={selectChiaveIccd}>ICCID</Dropdown.Item>
                                    <Dropdown.Item onClick={selectChiaveTelNumber}>Numero di telefono</Dropdown.Item>
                                    <Dropdown.Item onClick={selectChiaveImei}>IMEI</Dropdown.Item>
                                    <Dropdown.Item onClick={selectChiaveStato}>Stato</Dropdown.Item>
                                </DropdownButton>
                            </Col>
                            <Col>
                                <Form.Text className="text-muted">Valore</Form.Text>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Control style={{ width: "100%" }}
                                    required
                                    type="text"
                                    readOnly={true}
                                    value={chiave}
                                    placeholder={chiave}
                                />
                            </Col>
                            <Col >
                                <Form.Control style={{ width: "100%" }}
                                    required
                                    type="text"
                                    onChange={searchKitHandler}
                                    value={searchKit}
                                    readOnly={!isKeySelected}
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col >
                                <ClipLoader
                                    size={55}
                                    color={"#a71e3b"}
                                    loading={isLoading}
                                />
                            </Col>
                        </Form.Row>
                    </Form.Group>
                </Form>

                <BootstrapTable
                    bootstrap4
                    keyField="email"
                    data={dataKit}
                    columns={columns}
                    striped
                    hover
                    condensed
                    bordered={false}
                    rowEvents={rowEvents}
                />
            </React.Fragment>
        );
    }



    return ret;

}

export default GestioneKit