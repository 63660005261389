import React from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

function ReqPinConfirm(props) {
    var ret;

    if (!props.isSubmitted) {
        ret = (
            <React.Fragment>
                <Button variant="primary" type="submit" >
                    Submit
                </Button>
            </React.Fragment>
        );
    } else {
        switch (props.statusRequest) {
            case "success":
                ret = (
                    <React.Fragment>
                        <Alert variant="success">

                            <Alert.Heading>PIN valido!</Alert.Heading>
                            <p>
                                La conferma ricezione Badge Ateneo è stata acquisita
                            </p>
                        </Alert>
                        <Button href="/">Torna alla Home Page</Button>{' '}

                    </React.Fragment>
                );
                break;            
            case "dup":
                ret = (
                    <React.Fragment>
                        <Alert variant="warning">

                            <Alert.Heading>Il badge risulta già confermato!</Alert.Heading>
                            <p>
                                La conferma ricezione Badge Ateneo è già stata acquisita
                            </p>
                        </Alert>
                        <Button href="/">Torna alla Home Page</Button>{' '}

                    </React.Fragment>
                );
                break;
            default:
                ret = (
                    <React.Fragment>
                        <Alert variant="danger">

                            <Alert.Heading>PIN non valido!</Alert.Heading>
                            <p>
                                Immettere un PIN valido.
                            </p>
                        </Alert>
                        <Button href="/">Torna alla Home Page</Button>
                    </React.Fragment>
                );
        }

    }

    return ret;
}

export default ReqPinConfirm;