import React from 'react';
import Can from "../Can";
import Sim4studentPage from './Sim4studentPage';

function Tim4student(props) {
    var myHome;

    if (!props.apiAuthGroups) {
        myHome = (
            <React.Fragment>
                <h2>Non autorizzato</h2>
            </React.Fragment>
        )
    } else {
        myHome = (
            <Can
                role={props.apiAuthGroups}
                perform="tim4student-req-page:visit"
                yes={() => (
                    <Sim4studentPage
                        apiAuthResponse={props.apiAuthResponse}
                        apiAuthName={props.apiAuthName}
                        apiAuthSurname={props.apiAuthSurname}
                        apiAuthGroups={props.apiAuthGroups}
                        stuMatricola={props.stuMatricola}
                        tipoCorsoCod={props.tipoCorsoCod}
                        stuCodFis={props.stuCodFis}
                        stuIndiRes={props.stuIndiRes}
                        stuIndiDom={props.stuIndiDom}
                    />

                )}
                no={() => null}
            />
        )
    }


    return myHome;
}

export default Tim4student;