import React from 'react';
import { useBarcode } from 'react-barcodes';
import { padStart } from "lodash";

function VoucherBarcode(props) {
  const myValue = 'X' + padStart(String(props.value), 7, '0');

  const { inputRef } = useBarcode({
    value: myValue,
    options: {
      displayValue: true,
      //background: '#ffc0cb',
    }
  });
  
  return <canvas className="barCode" ref={inputRef} />;
};

export default VoucherBarcode;