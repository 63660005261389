import React from 'react';
import Form from 'react-bootstrap/Form';
import AsyncSelect from 'react-select/async'

function SelectSimIccd(props) {
    var ret;
    var mySel;


    const fetchIccd = (inputValue, callback) => {
        const tempArray = [];
        var formBody = [];
        const payload = {
            idSim: props.idSim,
            qrCode: props.qrCode,
            iccd: inputValue
        }
        setTimeout(async () => {
            formBody.push("payload=" + JSON.stringify(payload));
            await fetch(process.env.REACT_APP_URL_KITDATA_SIM_ICCD, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    data.forEach((element) => {
                        tempArray.push({
                            label: `${element.iccd}`,
                            value:
                            {
                                iccd: `${element.iccd}`,
                                telNumber: `${element.telNumber}`
                            }
                        });
                    });
                    callback(filterData(inputValue, tempArray));
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }

    function filterData(inputValue, myData) {
        return myData.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    function onSearchChangeIccd(selectedOption) {
        if (selectedOption) {
            props.setTelNumber(selectedOption.value.telNumber);
            props.setSerialNumber(selectedOption.value.iccd);
        }
    };

    var myPlaceHolder;
    if (props.serialNumber) {
        myPlaceHolder = props.serialNumber
    } else {
        myPlaceHolder = "Codice ICCD SIM"
    }

    mySel = (
        <React.Fragment>
            <AsyncSelect
                value=""
                placeholder={myPlaceHolder}
                loadOptions={fetchIccd}
                onChange={(e) => {
                    onSearchChangeIccd(e);
                }}
                //defaultOptions={true}
                components={{ DropdownIndicator: () => null }}
                noOptionsMessage={() => "Ricerca ICCD..."}
            />
            <input
                tabIndex={-1}
                autoComplete="off"
                style={{ opacity: 0, height: 0 }}
                required={true}
                value={props.serialNumber}
            />
        </React.Fragment>
    );


    ret = (
        <React.Fragment>
            <Form.Label>Codice ICCD SIM</Form.Label>
            <div>
                {mySel}
            </div>

        </React.Fragment>
    )

    return ret;

}


export default SelectSimIccd;