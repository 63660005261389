import React, { useState, useEffect } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import BootstrapTable from "react-bootstrap-table-next";
import ReactExport from 'react-export-excel';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import DatePicker, { registerLocale } from 'react-datepicker';
import it from "date-fns/locale/it";



import 'react-datepicker/dist/react-datepicker.css';

const l4Columns = [
    {
        dataField: "dtConfirm",
        text: "Data conferma",
        sort: true
    },
    {
        dataField: "isDomicilio",
        text: "Modalità consegna",
        sort: true
    },
    {
        dataField: "router_imei",
        text: "router_imei",
    },
    {
        dataField: "sim_iccd",
        text: "sim_iccd",
    },
    {
        dataField: "sim_telefono",
        text: "sim_telefono",
    },
    {
        dataField: "pdv_codPdv",
        text: "Codice Punto di Vendita",
        sort: true
    },
    {
        dataField: "pdv_desPdv",
        text: "Descrizione Punto di Vendita",
        sort: true
    },
    {
        dataField: "pdv_week",
        text: "Settimana ritiro",
        sort: true
    },
];


function L4Attivate(props) {
    const [isLoading, setIsLoading] = useState(1)
    const [parDtIni, setParDtIni] = useState();
    const [parDtFin, setParDtFin] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [l4Data, setL4Data] = useState([]);

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    var ret;

    registerLocale("it", it);


    function convertDate(inputFormat) {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
    };

    function setSelectedDate(selDate, type) {
        function pad(s) { return (s < 10) ? '0' + s : s; }

        var seldayDate = new Date(selDate);
        var selDay = [seldayDate.getFullYear(), pad(seldayDate.getMonth() + 1), pad(seldayDate.getDate())].join('-');

        if (type === "start") {
            console.log("det start date" + selDate)
            setStartDate(selDate);
            setParDtIni(selDay);
        } else {
            setEndDate(selDate)
            setParDtFin(selDay);
        }

    }

    useEffect(() => {
        function setDefaultRange() {
            function addDays(date, days) {
                var result = new Date(date);
                result.setDate(result.getDate() + days);
                return result;
            }

            function delDays(date, days) {
                var result = new Date(date);
                result.setDate(result.getDate() - days);
                return result;
            }            

            function pad(s) { return (s < 10) ? '0' + s : s; }
            var curr = new Date();

            var firstdayDate = delDays(curr, curr.getDay() - 1); 
            var lastdayDate = addDays(firstdayDate, 7); 

            var firstday = [firstdayDate.getFullYear(), pad(firstdayDate.getMonth() + 1), pad(firstdayDate.getDate())].join('-');
            var lastday = [lastdayDate.getFullYear(), pad(lastdayDate.getMonth() + 1), pad(lastdayDate.getDate())].join('-');

            setStartDate(firstdayDate);
            setEndDate(lastdayDate);
            setParDtIni(firstday);
            setParDtFin(lastday);
        }

        function fetchData() {
            setTimeout(async () => {
                setIsLoading(1);
                await fetch(process.env.REACT_APP_URL_CONSOLLETIM_L0 + '?lType=l4' +
                    '&dtIni=' + parDtIni +
                    '&dtFin=' + parDtFin, {
                    credentials: 'include',
                    method: "GET"
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        data.forEach(data => {
                            data.dtConfirm = convertDate(data.dtConfirm);
                            data.router_imei = data.dettUtilizzo.imei;
                            data.sim_iccd = data.dettUtilizzo.serialNumber;
                            data.sim_telefono = data.dettUtilizzo.telNumber;
                            data.createdAt = convertDate(data.createdAt);
                            data.isDomicilio = (data.isDomicilio ? 'Posta' : 'Centro TIM');
                            data.pdv_codPdv = data.pdv.codPdv;
                            data.pdv_desPdv = data.pdv.desPdv;
                            data.pdv_week = data.pdv.week;
                        });

                        setL4Data(data);
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });
                setIsLoading(0);
            });

        }

        setL4Data([])

        if (parDtIni && parDtFin) {
            fetchData();
        } else {
            setDefaultRange();
        }
    }, [parDtIni, parDtFin]);




    if (!l4Data) {
        ret = null;
    } else {

        ret = (
            <React.Fragment>
                <h2 className="title-page">Estrazione L4</h2>
                <p>SIM attive</p>
                <br /><br />

                <Form>
                    <Form.Group controlId="intervalSelectorL4" style={{ width: "85%" }}>
                        <Form.Label>Intervallo di estrazione</Form.Label>
                        <Form.Row>
                            <Col>
                                <Form.Text className="text-muted">Inizio</Form.Text>
                            </Col>
                            <Col>
                                <Form.Text className="text-muted">Fine</Form.Text>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col >
                                <DatePicker
                                    selected={startDate}
                                    onChange={date => setSelectedDate(date, 'start')}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    required
                                    dateFormat="dd/MM/yyyy"
                                    locale="it"
                                />
                            </Col>
                            <Col >
                                <DatePicker
                                    selected={endDate}
                                    onChange={date => setSelectedDate(date, 'end')}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    required
                                    dateFormat="dd/MM/yyyy"
                                    locale="it"
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col >
                                <ClipLoader
                                    size={55}
                                    color={"#a71e3b"}
                                    loading={isLoading}
                                />
                            </Col>
                        </Form.Row>
                    </Form.Group>
                </Form>

                <ExcelFile>
                    <ExcelSheet data={l4Data} name="SIM da attivare">
                        <ExcelColumn label="Data conferma" value="dtConfirm" />
                        <ExcelColumn label="Modalità consegna" value="isDomicilio" />                        
                        <ExcelColumn label="router_imei" value="router_imei" />
                        <ExcelColumn label="sim_iccd" value="sim_iccd" />
                        <ExcelColumn label="sim_telefono" value="sim_telefono" />
                        <ExcelColumn label="Codice PDV" value="pdv_codPdv" />
                        <ExcelColumn label="Descrizione PDV" value="pdv_desPdv" />
                        <ExcelColumn label="Settimana ritiro" value="pdv_week" />
                    </ExcelSheet>
                </ExcelFile>

                <BootstrapTable
                    bootstrap4
                    keyField="email"
                    data={l4Data}
                    columns={l4Columns}
                    striped
                    hover
                    condensed
                    bordered={false}
                />
            </React.Fragment>
        );
    }



    return ret;

}

export default L4Attivate;