import React from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { pdf } from '@react-pdf/renderer';
import SimDoc from './SimDoc';

var QRCode = require('qrcode.react');


function ReqSimConfirm(props) {
    var ret;

    const saveBlob = (blob, filename) => {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const savePdf = async (document, filename) => {
        saveBlob(await pdf(document).toBlob(), filename);
    };


    const saveSim = () => {
        const qrCodeCanvas = document.querySelector('canvas.qrCode');
        const qrCodeDataUri = qrCodeCanvas.toDataURL('image/jpg', 1);

        savePdf(<SimDoc
            qrCodeDataUri={qrCodeDataUri}
            qrCode={props.qrCode}
            idSim={props.idSim}
            isDomicilio={props.isDomicilio}
            pdv={props.pdv}
            indirizzo={props.indirizzo}
            stuMatricola={props.stuMatricola}
            apiAuthName={props.apiAuthName}
            apiAuthSurname={props.apiAuthSurname}
        />, "UnimibSIM" + props.stuMatricola + ".pdf")
    }

    if (!props.isSubmitted) {
        if (props.numPdvWeek > 0) {
            ret = (
                <React.Fragment>
                    <Button variant="primary" type="submit" >
                        Submit
                    </Button>
                </React.Fragment>
            );
        } else
         ret = (null)
    } else {
        var qrCodeLink;
        var barCodes;
        var buttonSave;

        if (props.isDomicilio === 0) {
            qrCodeLink = process.env.REACT_APP_URL_CONSUME_SIM
                + props.idSim + "/chk/"
                + props.qrCode;

            barCodes = (
                <React.Fragment>
                    <QRCode
                        className="qrCode"
                        value={qrCodeLink}
                        size={300}
                        level="H"
                    />
                </React.Fragment>
            );

            buttonSave = (
                <Button onClick={saveSim}>Download Richiesta SIM</Button>
            );
        }

        switch (props.statusRequest) {
            case "success":
                ret = (
                    <React.Fragment>
                        <Alert variant="success">
                            <Alert.Heading>Richiesta SIM dati e Router emessa con successo!</Alert.Heading>
                            <p>
                                La richiesta SIM dati e Router è stata generata.
                            </p>
                            {barCodes}
                        </Alert>
                        <Button href="/">Torna alla Home Page</Button>{' '}
                        {buttonSave}

                    </React.Fragment>
                );
                break;
            case "dup":
                ret = (
                    <React.Fragment>
                        <Alert variant="danger">

                            <Alert.Heading>Una richiesta SIM dati e Router per l'utente indicato risulta già emessa!</Alert.Heading>
                            <p>
                                La richiesta SIM dati e Router è già stata emessa.
                            </p>
                            {barCodes}
                        </Alert>
                        <Button href="/">Torna alla Home Page</Button>{' '}
                        {buttonSave}
                    </React.Fragment>
                );
                break;
                case "reprint":
                    ret = (
                        <React.Fragment>
                            <Alert variant="success">
    
                                <Alert.Heading>Richiesta SIM dati e Router</Alert.Heading>
                                <p>
                                    Di seguito le informaioni sulla richiesta SIM dati e Router.
                                </p>
                                {barCodes}
                            </Alert>
                            <Button href="/">Torna alla Home Page</Button>{' '}
                            {buttonSave}
                        </React.Fragment>
                    );
                    break;
            default:
                ret = (
                    <React.Fragment>
                        <Alert variant="danger">

                            <Alert.Heading>Si è verificato un errore!</Alert.Heading>
                            <p>
                                Contattare l'assistenza.
                            </p>
                        </Alert>
                        <Button href="/">Torna alla Home Page</Button>
                    </React.Fragment>
                );
        }

    }

    return ret;
}

export default ReqSimConfirm;