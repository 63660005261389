import React, { useState, useEffect } from 'react';


const hp = {
    height: "300px"
}

function RegisterLab(props) {
    const [registerResult, setRegisterResult] = useState(0);
    const [registerLab, setRegisterLab] = useState();

    var myHome;

    useEffect(() => {
        setTimeout(async () => {
            await fetch(process.env.REACT_APP_URL_REGISTER_LAB_BACKEND + '/' + props.handle, {
                credentials: 'include',
                method: "GET"
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    console.log(JSON.stringify(data));
                    if (data.length > 0) {
                        setRegisterResult(1);
                        setRegisterLab(data[0].labName);
                    } else {
                        setRegisterResult(0);
                    }
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                    setRegisterResult(0);
                });
        });
    },[props.handle]);



    myHome = (
        <React.Fragment>
            <div style={hp}>
                <h1 className="welcome">Benvenuto {props.apiAuthName} {props.apiAuthSurname}</h1>
                <h3>Registrazione avvenuta con successo!</h3>
                <br/>
                <p>Laboratorio: {registerLab}</p>
            </div>
        </React.Fragment>
    );


    var myWrong = (
        <React.Fragment>
            <div style={hp}>
                <h1 className="welcome">Benvenuto {props.apiAuthName} {props.apiAuthSurname}</h1>
                <h3>Registrazione fallita</h3>
            </div>
        </React.Fragment>
    );

    if (registerResult === 1)
        return myHome;
    else
        return myWrong;
}

export default RegisterLab;