import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import ClipLoader from 'react-spinners/ClipLoader';
import SimPdv from './SimPdv';
import ReqSimConfirm from './ReqSimConfirm';
import AgreementSim from './AgreementSim';


function Sim4studentPage(props) {
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [isLoading, setIsLoading] = useState(0);
    const [idSim, setIdSim] = useState();
    const [qrCode, setQrCode] = useState();
    const [statusRequest, setStatusRequest] = useState();
    const [selOpt, setSelOpt] = useState();
    const [selOptWeek, setSelOptWeek] = useState();
    const [isDomicilio, setIsDomicilio] = useState();
    const [storedIsDomicilio, setStoredIsDomicilio] = useState();
    const [indirizzo, setIndirizzo] = useState();
    const [pdv, setPdv] = useState();


    var myIndi;
    var mySimPdv;
    var myComune;
    var myProv;
    var myVia;
    var myCap;
    var myRegio;
    var myRet;

    useEffect(() => {
        const tempArray = [];
        setTimeout(async () => {
            await fetch(process.env.REACT_APP_URL_PDV_WEEK_AVAILABLE_SIM, {
                credentials: 'include',
                method: "GET",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    data.forEach((element) => {
                        tempArray.push({
                            label: `${element.week}`,
                            value: {
                                idWeek: `${element.idWeek}`,
                                week: `${element.week}`
                            }
                        });
                    });
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        var formBody = [];
        var selPdv = "";
        if (selOpt && selOptWeek) {
            if (selOpt.selectedOption.value && selOptWeek.selectedOptionWeek.value) {
                selPdv = {
                    codPdv: selOpt.selectedOption.value.codPdv,
                    desPdv: encodeURIComponent(selOpt.selectedOption.value.desPdv),
                    week: selOptWeek.selectedOptionWeek.value.week,
                    idWeek: selOptWeek.selectedOptionWeek.value.idWeek
                };
            }
        }

        const payload = {
            tipoCorsoCod: props.tipoCorsoCod,
            indirizzo: myIndi,
            via: myVia,
            cap: myCap,
            prov: myProv,
            comune: myComune,
            regione: myRegio,
            pdv: selPdv,
            isDomicilio: isDomicilio
        }

        setTimeout(async () => {
            setIsLoading(1);
            formBody.push("payload=" + JSON.stringify(payload));
            await fetch(process.env.REACT_APP_URL_REQ_SIM, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    setIsSubmitted(1);
                    setIsLoading(0);
                    setIdSim(data.idSim);
                    setQrCode(data.qrCode);
                    setStoredIsDomicilio(data.isDomicilio);
                    setPdv(data.pdv);
                    setIndirizzo(data.indirizzo);
                    setStatusRequest(data.statusRequest);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }


    if (props.stuIndiDom && props.stuIndiRes) {
        myIndi = (props.stuIndiDom.viaDom ?
            props.stuIndiDom.viaDom + ", " + props.stuIndiDom.numCivDom + " " +
            props.stuIndiDom.capDom + " " + props.stuIndiDom.comuneDom + " " +
            props.stuIndiDom.provDom + " " +
            props.stuIndiDom.frazDom + " " + props.stuIndiDom.citstraDom + " " +
            props.stuIndiDom.nazioneDom
            :
            props.stuIndiRes.viaRes + ", " + props.stuIndiRes.numCivRes + " " +
            props.stuIndiRes.capRes + " " + props.stuIndiRes.comuneRes + " " +
            props.stuIndiDom.provRes + " " +
            props.stuIndiRes.frazRes + " " + props.stuIndiRes.citstraRes + " " +
            props.stuIndiRes.nazioneRes
        );
        myComune = (props.stuIndiDom.viaDom ?
            props.stuIndiDom.comuneDom
            :
            props.stuIndiRes.comuneRes
        );
        myProv = (props.stuIndiDom.viaDom ?
            props.stuIndiDom.provDom
            :
            props.stuIndiRes.provRes
        );
        myCap = (props.stuIndiDom.capDom ?
            props.stuIndiDom.capDom
            :
            props.stuIndiRes.capRes
        );
        myVia = (props.stuIndiDom.capDom ?
            props.stuIndiDom.viaDom + ", " + props.stuIndiDom.numCivDom
            :
            props.stuIndiRes.viaRes + ", " + props.stuIndiRes.numCivRes
        );
        myRegio = (props.stuIndiDom.regioDom ?
            props.stuIndiDom.regioDom
            :
            props.stuIndiRes.regioRes
        );


    }

    mySimPdv = (
        <SimPdv
            apiAuthName={props.apiAuthName}
            myIndi={myIndi}
            comune={myComune}
            prov={myProv}
            setSelOpt={setSelOpt}
            selOpt={selOpt}
            setSelOptWeek={setSelOptWeek}
            selOptWeek={selOptWeek}
            setIsDomicilio={setIsDomicilio}
            isDomicilio={isDomicilio}
            numPdvWeek="1"
            stuIndiRes={props.stuIndiRes}
        />
    );


    if (!props.apiAuthName)
        myRet = null;
    else {
        if (!props.stuMatricola) {
            myRet = (
                <React.Fragment>
                    <h2 className="title-page">Richiesta SIM dati e Router</h2>
                    <p>Posizione irregolare. Contattare l'assistenza.</p>
                </React.Fragment>
            )
        } else {
            myRet = (
                <React.Fragment>
                    <h2 className="title-page">Richiesta SIM dati e Router</h2>
                    <p>Da questa pagina è possibile richiedere una SIM dati comprensiva di Router</p>
                    <Form onSubmit={onSubmit}>
                        <Form.Group style={{ width: "100%" }}>
                            <Form.Label>Nome richiedente</Form.Label>
                            <Form.Control style={{ width: "100%" }}
                                type="text"
                                placeholder={`${props.apiAuthName}`}
                                readOnly
                            />
                            <br />
                            <Form.Label>Cognome richiedente</Form.Label>
                            <Form.Control style={{ width: "100%" }}
                                type="text"
                                placeholder={`${props.apiAuthSurname}`}
                                readOnly
                            />
                            <br />
                            <Form.Label>Codice Fiscale richiedente</Form.Label>
                            <Form.Control style={{ width: "100%" }}
                                type="text"
                                placeholder={`${props.stuCodFis}`}
                                readOnly
                            />
                            <br />
                            <Form.Label>E-mail richiedente</Form.Label>
                            <Form.Control style={{ width: "100%" }}
                                type="text"
                                placeholder={`${props.apiAuthResponse}`}
                                readOnly
                            />
                            <br />
                            <Form.Label>Matricola richiedente</Form.Label>
                            <Form.Control style={{ width: "100%" }}
                                type="text"
                                placeholder={`${props.stuMatricola}`}
                                readOnly
                            />
                            <br />
                            <Form.Label>Codice tipologia Corso di Studi</Form.Label>
                            <Form.Control style={{ width: "100%" }}
                                type="text"
                                placeholder={`${props.tipoCorsoCod}`}
                                readOnly
                            />
                            <br />
                            {mySimPdv}

                        </Form.Group>


                        <AgreementSim
                            //numPdvWeek={numPdvWeek}
                            numPdvWeek="1"
                        />

                        <div>
                            <ReqSimConfirm
                                isSubmitted={isSubmitted}
                                statusRequest={statusRequest}
                                stuMatricola={props.stuMatricola}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                qrCode={qrCode}
                                idSim={idSim}
                                isDomicilio={storedIsDomicilio}
                                indirizzo={indirizzo}
                                pdv={pdv}
                                //numPdvWeek={numPdvWeek}
                                numPdvWeek="1"
                            />
                            <br />
                            <ClipLoader
                                size={35}
                                color={"#a71e3b"}
                                loading={isLoading}
                            />
                        </div>
                    </Form>

                </React.Fragment>
            );
        }
    }

    return myRet;
}

export default Sim4studentPage;
