import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import L0RichiesteTotali from './L0RichiesteTotali';


function VoucherConsollePage(props) {
    var ret;

    ret = (
        <React.Fragment >
            <br />
            <Tab.Container id="left-tabs-voucher-consolle" defaultActiveKey="l0">
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="l0">L0 - Richieste totali</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="l0">
                                <L0RichiesteTotali />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
            <br />
            <br />
        </React.Fragment>




    );

    return ret;


}

export default VoucherConsollePage;