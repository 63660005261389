import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import SelectAd from './SelectAd';
import SelectRepoPkg from './SelectRepoPkg';
import ReqLabConfirm from './ReqLabConfirm';
import SelectBaseImage from './SelectBaseImage';
import SelectSize from './SelectSize';
import LabName from './LabName';
import ClipLoader from 'react-spinners/ClipLoader';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';


function ReqLabPage(props) {
    const [adSel, setAdSel] = useState();
    const [repoPkgSel, setRepoPkgSel] = useState();
    const [labName, setLabName] = useState();
    const [labDescription, setLabDescription] = useState();
    const [labNotes, setLabNotes] = useState();
    const [labOtherActivities, setLabOtherActivities] = useState();
    const [baseImage, setBaseImage] = useState({ os: "windows", name: process.env.REACT_APP_DEFAULT_BASE_IMAGE })
    const [size, setSize] = useState(process.env.REACT_APP_DEFAULT_SIZE);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dailyStartHour, setDailyStartHour] = useState(null);
    const [dailyEndHour, setDailyEndHour] = useState(null);

    const [isSubmitble, setIsSubmitble] = useState();
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [isLoading, setIsLoading] = useState(0);
    const [statusRequest, setStatusRequest] = useState();


    const onSubmit = (e) => {
        e.preventDefault();
        //const repoPkgSelValue = [];
        var formBody = [];
        const payload = (
            {
                email: props.apiAuthResponse,
                labName: labName,
                labDescription: labDescription,
                //repoPkgSel: repoPkgSelValue,
                repoPkgSel: repoPkgSel,
                adSel: adSel,
                labOtherActivities: labOtherActivities,
                labNotes: labNotes,
                baseImage: baseImage,
                size: size,
                usageData: {
                    startDate: startDate,
                    endDate: endDate,
                    dailyStartHour: dailyStartHour,
                    dailyEndHour: dailyEndHour
                }
            }
        );

        //repoPkgSel.map(({ value }) => repoPkgSelValue.push(value));
        formBody.push("payload=" + JSON.stringify(payload));

        setTimeout(async () => {
            console.log(JSON.stringify(payload));
            setIsLoading(1);
            await fetch(process.env.REACT_APP_URL_SAVE_REQ_LAB, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    console.log(JSON.stringify(data));
                    setIsSubmitted(1);
                    setIsLoading(0);
                    setStatusRequest(data.statusRequest);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }

    const labDescriptionHandler = (event) => {
        setLabDescription(event.target.value);
    }

    const labNotesHandler = (event) => {
        setLabNotes(event.target.value);
    }

    const labBaseImageHandler = (event) => {
        setBaseImage(event.target.id);
    }

    return (
        <React.Fragment>
            <h2 className="title-page">Richiesta Laboratorio Virtuale</h2>
            <p>Da questa pagina è possibile attivare un Laboratorio Virtuale</p>
            <Form onSubmit={onSubmit}>
                <Form.Group style={{ width: "100%" }}>
                    <Form.Label>Nome richiedente</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.apiAuthName}`}
                        readOnly
                    />
                    <br />
                    <Form.Label>Cognome richiedente</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.apiAuthSurname}`}
                        readOnly
                    />
                    <br />
                    <Form.Label>E-mail richiedente</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.apiAuthResponse}`}
                        readOnly
                    />
                    <br />
                </Form.Group>

                <Form.Group controlId="formLabRequest" style={{ width: "85%" }}>
                    <SelectAd
                        apiAuthResponse={props.apiAuthResponse}
                        apiAuthName={props.apiAuthName}
                        apiAuthSurname={props.apiAuthSurname}
                        apiAuthGroups={props.apiAuthGroups}
                        adSelected={setAdSel}
                        labOtherActivitiesSelected={setLabOtherActivities}
                        labName={labName}
                    />
                </Form.Group>

                <SelectSize
                    setSize={setSize}
                    size={size}
                />

                <SelectBaseImage
                    labBaseImageHandler={labBaseImageHandler}
                    baseImage={baseImage}
                />

                <Form.Group controlId="formLabRequestSw" style={{ width: "85%" }}>
                    <Form.Label>Software richiesto</Form.Label>
                    <Form.Text className="text-muted">
                        Dati estratti dal repository LIBaaS.
                    </Form.Text>
                    <SelectRepoPkg
                        apiAuthResponse={props.apiAuthResponse}
                        apiAuthName={props.apiAuthName}
                        apiAuthSurname={props.apiAuthSurname}
                        apiAuthGroups={props.apiAuthGroups}
                        repoPkgSelected={setRepoPkgSel}
                        baseImage={baseImage}
                        isNew={1}
                    />
                </Form.Group>

                <Form.Group style={{ width: "100%" }}>
                    <LabName
                        apiAuthResponse={props.apiAuthResponse}
                        apiAuthName={props.apiAuthName}
                        apiAuthSurname={props.apiAuthSurname}
                        apiAuthGroups={props.apiAuthGroups}
                        adSel={adSel}
                        labNameSelected={setLabName}
                        labName={labName}
                        setIsSubmitble={setIsSubmitble}
                        isSubmitted={isSubmitted}
                    />
                    <br />
                    <Form.Label>Descrizione del Laboratorio Virtuale</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        onChange={labDescriptionHandler}
                        placeholder="Descrizione del Laboratorio" />
                    <br />
                    <Form.Label>Note aggiuntive</Form.Label>
                    <Form.Control style={{ width: "85%" }}
                        as="textarea"
                        rows="3"
                        maxlength="2000"
                        placeholder="Inserire eventuali note aggiuntive"
                        onChange={labNotesHandler}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Periodo di utilizzo</Form.Label>
                    <Form.Row>
                        <Col xs={3}>
                            <DatePicker
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                required
                                placeholderText="Inizio"
                            />
                        </Col>
                        <Col xs={3}>
                            <DatePicker
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                required
                                placeholderText="Fine"
                            />
                        </Col>
                    </Form.Row>
                </Form.Group>


                <Form.Group>
                    <Form.Label>Fascia oraria di utilizzo</Form.Label>
                    <Form.Row>
                        <Col xs={3}>
                            <DatePicker
                                selected={dailyStartHour}
                                onChange={date => setDailyStartHour(date)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Inizio"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                required
                                placeholderText="Inizio"
                            />
                        </Col>
                        <Col xs={3}>
                            <DatePicker
                                selected={dailyEndHour}
                                onChange={date => setDailyEndHour(date)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Fine"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                required
                                placeholderText="Fine"
                            />
                        </Col>
                    </Form.Row>
                </Form.Group>

                <div>
                    <ReqLabConfirm
                        isSubmitted={isSubmitted}
                        isSubmitble={isSubmitble}
                        statusRequest={statusRequest}
                    />
                    <br />
                    <ClipLoader
                        size={35}
                        color={"#a71e3b"}
                        loading={isLoading}
                    />
                </div>
            </Form>

        </React.Fragment>
    );

}

export default ReqLabPage;
