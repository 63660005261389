import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';



function SelectSize(props) {
    const [currValue, setCurrValue] = useState(0);
    var ret;

    const rangeHandler = (e) => {
        switch (e.target.value) {
            case "0":
                props.setSize("small");
                break;
            case "1":
                props.setSize("medium");
                break;
            case "2":
                props.setSize("large");
                break;
            default:
                props.setSize("small");
                break;
        }
        setCurrValue(e.target.value);
    }

    const tooltipHandler = (e) => {
        switch (e) {
            case "0":
                return "Small - 2 CPU, 4GB RAM";                
            case "1":
                return "Medium  - 4 CPU, 8GB RAM"
            case "2":
                return "Large  - 8 CPU, 16GB RAM"
            default:
                return "Small - 2 CPU, 4GB RAM"
        }

    }



    ret = (
        <Form.Group controlId="formLabRequestSo" style={{ width: "85%" }}>
            <Form.Label>Dimensionamento macchine</Form.Label>
            <RangeSlider
                min="0"
                max="2"
                value={currValue}
                onChange={changeEvent => rangeHandler(changeEvent)}
                tooltip="on"
                tooltipPlacement="bottom"
                tooltipLabel={val => tooltipHandler(val)}
            />
            <br />
        </Form.Group>

    );

    return ret;
}

export default SelectSize;