import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import GestioneImei from './GestioneImei';
import GestioneSim from './GestioneSim';
import GestioneKit from './GestioneKit';

function TimInventoryPage(props) {
    var ret;

    ret = (
        <React.Fragment >
            <br />
            <Tabs defaultActiveKey="l0" id="tim-inventory">
                <Tab eventKey="l0" title="Router non utilizzati">
                    <GestioneImei />
                </Tab>
                <Tab eventKey="l1" title="SIM non utilizzate">
                    <GestioneSim />
                </Tab>
                <Tab eventKey="l2" title="Gestione KIT utilizzati">
                    <GestioneKit />
                </Tab>
            </Tabs>

            <br />
            <br />
        </React.Fragment>




    );

    return ret;


}

export default TimInventoryPage;