import React, { useState, useEffect } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import BootstrapTable from "react-bootstrap-table-next";
import ReactExport from 'react-export-excel';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import DatePicker, { registerLocale } from 'react-datepicker';
import it from "date-fns/locale/it";



import 'react-datepicker/dist/react-datepicker.css';

const l1Columns = [
    {
        dataField: "lotto",
        text: "lotto",
    },
    {
        dataField: "stato",
        text: "stato",
    },
    {
        dataField: "id_spedizione",
        text: "id_spedizione",
    },
    {
        dataField: "societa_fatturazione",
        text: "societa_fatturazione",
    },
    {
        dataField: "ragione_sociale",
        text: "ragione_sociale",
    },
    {
        dataField: "nome",
        text: "nome_referente",
    },
    {
        dataField: "cognome",
        text: "cognome_referente",
    },
    {
        dataField: "riferimento_telefonico",
        text: "riferimento_telefonico",
    },
    {
        dataField: "mail_username",
        text: "mail_username",
    },
    {
        dataField: "data_di_nascita",
        text: "data_di_nascita",
    },
    {
        dataField: "codFis",
        text: "codice_fiscale",
        sort: false
    },
    {
        dataField: "sede_di_riferimento",
        text: "sede_di_riferimento",
        sort: false
    },
    {
        dataField: "struttura",
        text: "struttura",
        sort: false
    },
    {
        dataField: "indir_domicilio",
        text: "indir_domicilio",
        sort: false
    },
    {
        dataField: "via",
        text: "indir_consegna",
        sort: false
    },
    {
        dataField: "cap",
        text: "cap",
        sort: false
    },
    {
        dataField: "regione",
        text: "regione",
        sort: false
    },
    {
        dataField: "prov",
        text: "province",
        sort: false
    },
    {
        dataField: "comune",
        text: "citta",
        sort: false
    },
    {
        dataField: "indirizzo",
        text: "indirizzo",
        sort: false
    },
    {
        dataField: "cap_1",
        text: "cap_1",
        sort: false
    },
    {
        dataField: "nmu",
        text: "nmu",
        sort: false
    },
    {
        dataField: "qta_prodotto",
        text: "qta_prodotto",
        sort: false
    },
    {
        dataField: "nmu_sim",
        text: "nmu_sim",
        sort: false
    },
    {
        dataField: "qta_sim",
        text: "qta_sim",
        sort: false
    },
    {
        dataField: "ddt_ol",
        text: "ddt_ol",
        sort: false
    },
    {
        dataField: "serialei_assegnato",
        text: "serialei_assegnato",
        sort: false
    },
    {
        dataField: "iccid_assegnato",
        text: "iccid_assegnato",
        sort: false
    },
    {
        dataField: "uten_assegnato",
        text: "uten_assegnato",
        sort: false
    },
    {
        dataField: "collo_ol",
        text: "collo_ol",
        sort: false
    },
    {
        dataField: "data_affido",
        text: "data_affido",
        sort: false
    },
    {
        dataField: "esito_consegna",
        text: "esito_consegna",
        sort: false
    },
    {
        dataField: "data_esito",
        text: "data_esito",
        sort: false
    },
    {
        dataField: "data_consegna",
        text: "data_consegna",
        sort: false
    },
];


function L1Posta(props) {
    const [isLoading, setIsLoading] = useState(1)
    const [parDtIni, setParDtIni] = useState();
    const [parDtFin, setParDtFin] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [l1Data, setL1Data] = useState([]);

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    var ret;

    registerLocale("it", it);


    function convertDate(inputFormat) {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
    };

    function setSelectedDate(selDate, type) {
        function pad(s) { return (s < 10) ? '0' + s : s; }

        var seldayDate = new Date(selDate);
        var selDay = [seldayDate.getFullYear(), pad(seldayDate.getMonth() + 1), pad(seldayDate.getDate())].join('-');

        if (type === "start") {
            console.log("det start date" + selDate)
            setStartDate(selDate);
            setParDtIni(selDay);
        } else {
            setEndDate(selDate)
            setParDtFin(selDay);
        }

    }

    useEffect(() => {
        function setDefaultRange() {
            function addDays(date, days) {
                var result = new Date(date);
                result.setDate(result.getDate() + days);
                return result;
            }

            function delDays(date, days) {
                var result = new Date(date);
                result.setDate(result.getDate() - days);
                return result;
            }

            function pad(s) { return (s < 10) ? '0' + s : s; }
            var curr = new Date();

            var firstdayDate = delDays(curr, curr.getDay() - 1);
            var lastdayDate = addDays(firstdayDate, 7);

            var firstday = [firstdayDate.getFullYear(), pad(firstdayDate.getMonth() + 1), pad(firstdayDate.getDate())].join('-');
            var lastday = [lastdayDate.getFullYear(), pad(lastdayDate.getMonth() + 1), pad(lastdayDate.getDate())].join('-');

            setStartDate(firstdayDate);
            setEndDate(lastdayDate);
            setParDtIni(firstday);
            setParDtFin(lastday);
        }

        function fetchData() {
            setTimeout(async () => {
                setIsLoading(1);
                await fetch(process.env.REACT_APP_URL_CONSOLLETIM_L0 + '?lType=l1' +
                    '&dtIni=' + parDtIni +
                    '&dtFin=' + parDtFin, {
                    credentials: 'include',
                    method: "GET"
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        data.forEach(data => {
                            data.lotto = 1;
                            data.societa_fatturazione = 'Università degli Studi di Milano - Bicocca';
                            data.ragione_sociale = data.cognome + ' ' + data.nome;
                            data.createdAt = convertDate(data.createdAt);
                            data.nmu = '772328';
                            data.qta_prodotto = 1;
                            data.nmu_sim = '775007';
                            data.qta_sim = 1;
                        });

                        setL1Data(data);
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });
                setIsLoading(0);
            });

        }

        setL1Data([])

        if (parDtIni && parDtFin) {
            fetchData();
        } else {
            setDefaultRange();
        }
    }, [parDtIni, parDtFin]);




    if (!l1Data) {
        ret = null;
    } else {

        ret = (
            <React.Fragment>
                <h2 className="title-page">Estrazione L1</h2>
                <p>Spedizione delle SIM a mezzo posta</p>
                <br /><br />

                <Form>
                    <Form.Group controlId="intervalSelectorL1" style={{ width: "85%" }}>
                        <Form.Label>Intervallo di estrazione</Form.Label>
                        <Form.Row>
                            <Col>
                                <Form.Text className="text-muted">Inizio</Form.Text>
                            </Col>
                            <Col>
                                <Form.Text className="text-muted">Fine</Form.Text>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col >
                                <DatePicker
                                    selected={startDate}
                                    onChange={date => setSelectedDate(date, 'start')}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    required
                                    dateFormat="dd/MM/yyyy"
                                    locale="it"
                                />
                            </Col>
                            <Col >
                                <DatePicker
                                    selected={endDate}
                                    onChange={date => setSelectedDate(date, 'end')}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    required
                                    dateFormat="dd/MM/yyyy"
                                    locale="it"
                                />
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col >
                                <ClipLoader
                                    size={55}
                                    color={"#a71e3b"}
                                    loading={isLoading}
                                />
                            </Col>
                        </Form.Row>
                    </Form.Group>
                </Form>

                <ExcelFile>
                    <ExcelSheet data={l1Data} name="Richieste SIM da consegnare a mezzo Posta">
                        <ExcelColumn label="lotto" value="lotto" />
                        <ExcelColumn label="stato" value="stato" />
                        <ExcelColumn label="id_spedizione" value="id_spedizione" />
                        <ExcelColumn label="societa_fatturazione" value="societa_fatturazione" />
                        <ExcelColumn label="ragione_sociale" value="ragione_sociale" />
                        <ExcelColumn label="nome_referente" value="nome" />
                        <ExcelColumn label="cognome_referente" value="cognome" />
                        <ExcelColumn label="riferimento_telefonico" value="riferimento_telefonico" />
                        <ExcelColumn label="mail_username" value="mail_username" />
                        <ExcelColumn label="data_di_nascita" value="data_di_nascita" />
                        <ExcelColumn label="codice_fiscale" value="codFis" />
                        <ExcelColumn label="sede_di_riferimento" value="sede_di_riferimento" />
                        <ExcelColumn label="struttura" value="struttura" />
                        <ExcelColumn label="indir_domicilio" value="indir_domicilio" />
                        <ExcelColumn label="indir_consegna" value="via" />
                        <ExcelColumn label="cap" value="cap" />
                        <ExcelColumn label="regione" value="regione" />
                        <ExcelColumn label="province" value="prov" />
                        <ExcelColumn label="citta" value="comune" />
                        <ExcelColumn label="indirizzo" value="" />
                        <ExcelColumn label="cap_1" value="cap_1" />
                        <ExcelColumn label="nmu" value="nmu" />
                        <ExcelColumn label="qta_prodotto" value="qta_prodotto" />
                        <ExcelColumn label="nmu_sim" value="nmu_sim" />
                        <ExcelColumn label="qta_sim" value="qta_sim" />
                        <ExcelColumn label="ddt_ol" value="ddt_ol" />
                        <ExcelColumn label="serialei_assegnato" value="serialei_assegnato" />
                        <ExcelColumn label="iccid_assegnato" value="iccid_assegnato" />
                        <ExcelColumn label="uten_assegnato" value="uten_assegnato" />
                        <ExcelColumn label="collo_ol" value="collo_ol" />
                        <ExcelColumn label="data_affido" value="data_affido" />
                        <ExcelColumn label="esito_consegna" value="esito_consegna" />
                        <ExcelColumn label="data_esito" value="data_esito" />
                        <ExcelColumn label="data_consegna" value="data_consegna" />
                    </ExcelSheet>
                </ExcelFile>

                <BootstrapTable
                    bootstrap4
                    keyField="email"
                    data={l1Data}
                    columns={l1Columns}
                    striped
                    hover
                    condensed
                    bordered={false}
                />
            </React.Fragment>
        );
    }



    return ret;

}

export default L1Posta;