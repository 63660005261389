import React from 'react';
import Can from "../Can";
import PopsostudentPage from './PopsostudentPage';
import PopsoNotEnabled from './PopsoNotEnabled';

function Popsostudent(props) {
    var myHome;

    if (!props.apiAuthGroups) {
        return (
            <React.Fragment>
                <h2>Non autorizzato</h2>
            </React.Fragment>
        );
    }

    myHome = (
        <Can
            role={props.apiAuthGroups}
            perform="popsostudent-page:visit"
            yes={() => (
                <PopsostudentPage
                    apiAuthResponse={props.apiAuthResponse}
                    apiAuthName={props.apiAuthName}
                    apiAuthSurname={props.apiAuthSurname}
                    apiAuthGroups={props.apiAuthGroups}
                    stuMatricola={props.stuMatricola}
                    stuIndiRes={props.stuIndiRes}
                    stuIndiDom={props.stuIndiDom}
                />
            )}
            no={() => (
                <PopsoNotEnabled
                    apiAuthResponse={props.apiAuthResponse}
                    apiAuthName={props.apiAuthName}
                    apiAuthSurname={props.apiAuthSurname}
                    apiAuthGroups={props.apiAuthGroups}
                />)}
        />
    )



    return myHome;
}

export default Popsostudent;