import React, { useState, useEffect } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import BootstrapTable from "react-bootstrap-table-next";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';



const columns = [
    {
        dataField: "iccd",
        text: "ICCID",
    },
    {
        dataField: "telNumber",
        text: "Numero",
    },
];


function GestioneSim(props) {
    const [isLoading, setIsLoading] = useState(1)
    const [dataSim, setDataSim] = useState([]);
    const [searchIccd, setSearchIccd] = useState("");
    const [searchTelNumber, setSearchTelNumber] = useState("");
    const [isAddable, setIsAddable] = useState(false);

    var ret;

    const searchIccdHandler = (sim) => {
        //setSearchTelNumber("");
        setSearchIccd(sim.target.value);
    }

    const searchTelNumberHandler = (sim) => {
        //setSearchIccd("");
        setSearchTelNumber(sim.target.value);
    }


    // searchIccd
    useEffect(() => {
        var formBody = [];
        var payload = {
            iccd: searchIccd
        }

        function fetchData() {
            setTimeout(async () => {
                setIsLoading(1);

                formBody.push("payload=" + JSON.stringify(payload));
                await fetch(process.env.REACT_APP_URL_KITDATA_ADMIN_SIM_ICCD, {
                    credentials: 'include',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: formBody
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        setDataSim(data);
                        if (data.length > 0) {
                            setIsAddable(false);
                        } else {
                            setIsAddable(true);
                        }
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });

                setIsLoading(0);
            });

        }

        setDataSim([])

        fetchData();
    }, [searchIccd]);


    // searchTelNumber
    useEffect(() => {
        var formBody = [];
        var payload = {
            telNumber: searchTelNumber
        }

        function fetchData() {
            setTimeout(async () => {
                setIsLoading(1);

                formBody.push("payload=" + JSON.stringify(payload));
                await fetch(process.env.REACT_APP_URL_KITDATA_ADMIN_SIM_TELNUMBER, {
                    credentials: 'include',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: formBody
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        setDataSim(data);
                        if (data.length > 0) {
                            setIsAddable(false);
                        } else {
                            setIsAddable(true);
                        }
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });

                setIsLoading(0);
            });

        }

        setDataSim([])

        fetchData();
    }, [searchTelNumber]);


    const onSubmit = (e) => {
        e.preventDefault();
        var formBody = [];

        var url;

        url = process.env.REACT_APP_URL_KITDATA_ADMIN_ADD_SIM;

        const payload = (
            {
                iccd: searchIccd,
                telNumber: searchTelNumber,
                used: '0',
            }
        );

        formBody.push("payload=" + JSON.stringify(payload));

        setTimeout(async () => {
            console.log("payload (ReqLabPage): " + JSON.stringify(payload));
            setIsLoading(1);
            await fetch(url, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    setIsLoading(0);
                    setSearchTelNumber("");
                    setSearchIccd("");
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }

    const addNewSim = () => {
        var ret = null;

        if (isAddable) {
            ret = (
                <Button variant="primary" type="submit" >
                    {"Nuovo"}
                </Button>
            );
        }

        return ret;
    }




    if (!dataSim) {
        ret = null;
    } else {

        ret = (
            <React.Fragment>
                <h2 className="title-page">Inventario SIM non utilizzate</h2>
                <p>Ricerca delle SIM non ancora attribuite</p>
                <br /><br />

                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="searchSim" style={{ width: "85%" }}>
                        <Form.Label>Ricerca</Form.Label>
                        <Form.Row>
                            <Col></Col>
                            <Col>
                                <Form.Text className="text-muted">ICCID</Form.Text>
                            </Col>
                            <Col>
                                <Form.Text className="text-muted">Numero telefonico</Form.Text>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                {addNewSim()}
                            </Col>
                            <Col >
                                <Form.Control style={{ width: "100%" }}
                                    required
                                    type="text"
                                    onChange={searchIccdHandler}
                                    value={searchIccd}
                                    placeholder="ICCID della SIM" />
                            </Col>
                            <Col >
                                <Form.Control style={{ width: "100%" }}
                                    required
                                    type="text"
                                    onChange={searchTelNumberHandler}
                                    value={searchTelNumber}
                                    placeholder="Numero telefonico della SIM" />
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col >
                                <ClipLoader
                                    size={55}
                                    color={"#a71e3b"}
                                    loading={isLoading}
                                />
                            </Col>
                        </Form.Row>
                    </Form.Group>
                </Form>


                <BootstrapTable
                    bootstrap4
                    keyField="iccd"
                    data={dataSim}
                    columns={columns}
                    striped
                    hover
                    condensed
                    bordered={false}
                />
            </React.Fragment>
        );
    }



    return ret;

}

export default GestioneSim;