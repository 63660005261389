import React from 'react';
import Can from "../Can";
import RegisterLabPage from "./RegisterLabPage";


function RegisterLab(props) {
    var myHome;

    if (!props.apiAuthGroups) {
        myHome = (
            null
        )
    } else {
        myHome = (
            <Can
                role={props.apiAuthGroups}
                perform="labRegister-page:visit"
                yes={() => (
                    <RegisterLabPage
                        title={props.title}
                        handle={props.match.params.handle}
                        apiAuthResponse={props.apiAuthResponse}
                        apiAuthName={props.apiAuthName}
                        apiAuthSurname={props.apiAuthSurname}
                        apiAuthGroups={props.apiAuthGroups}
                    />
                )}
                no={() => (null)}
            />
        )
    }

    return myHome;
}

export default RegisterLab;