import React from 'react';


function PopsoNotEnabled(props) {
    var myRet = (
        <React.Fragment>
            <h2 className="title-page">Conferma ricezione Badge Ateneo+</h2>
            <p>Non sono presenti badge da attivare per il suo account.</p>
            <br />
            <a href="https://s3w.si.unimib.it">Segreterie On Line</a>
            <br />
        </React.Fragment>
    )

    return myRet;
}

export default PopsoNotEnabled;