import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';


// Create styles
const styles = StyleSheet.create({
    page: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    view: {
        width: '100%',
        height: '100%',
        padding: 0,
        backgroundColor: 'white',
    },
    qrCode: {
        width: '300px',
        height: 'auto',
        marginVertical: 15,
        marginHorizontal: 110,
    },
    barCode: {
        width: '100px',
        height: 'auto',
        marginVertical: 15,
        marginHorizontal: 100,
    },
    bicLogo: {
        width: '90px',
        height: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 230,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        textAlign: 'center'
    },
    section2: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 30,
    },
    section3: {
        margin: 0,
        padding: 0,
        flexGrow: 1,
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 4,
    },
    section4: {
        margin: 0,
        padding: 0,
        marginHorizontal: 60,
        flexGrow: 1,
        fontSize: 12,
        textAlign: 'left',
        marginBottom: 4,
    }
});


// Create Document Component
const SimDoc = (props) => {
    var bicLogo = process.env.REACT_APP_URL_LOGO_UNIMIB;
    var modalitaConsegna;

    if (props.isDomicilio === 1) {
        modalitaConsegna = (
            <React.Fragment>
                <Text style={styles.section3}>La SIM e il Router verranno recapitati a mezzo posta al seguente indirizzo:</Text>
                <Text style={styles.section3}>{props.indirizzo}</Text>
            </React.Fragment>
        );
    } else {
        modalitaConsegna = (
            <React.Fragment>
                <Text style={styles.section3}>Recarsi presso il seguente centro TIM per effefttuare il ritiro di SIM e Router:</Text>
                <Text style={styles.section3}> {props.pdv.desPdv} </Text>
                <Text style={styles.section3}> Nel periodo{" "}{props.pdv.week}</Text>           
                <Text style={styles.section4}>Nome: {props.apiAuthName}</Text>
                <Text style={styles.section4}>Cognome: {props.apiAuthSurname}</Text>
                <Text style={styles.section4}>Matricola: {props.stuMatricola}</Text>                     
            </React.Fragment >

        );
    }

    var myRet = (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={{ textAlign: 'center' }}>
                    <Image style={styles.bicLogo} src={bicLogo} alt="bicLogo" />
                    <Text >Università degli Studi di Milano-Bicocca</Text>
                </View>
                <View>
                    <Text style={styles.section}>
                        Iniziativa SIM4STUDENT
                    </Text>
                    <Text style={styles.section2}>
                        Valido per la richiesta del kit SIM dati e Router.
                    </Text>
                    {modalitaConsegna}
                    <Image style={styles.qrCode} src={props.qrCodeDataUri} alt="qrCode" />
                </View>
            </Page>
        </Document>

    );

    return myRet;

};

export default SimDoc;